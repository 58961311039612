import { Divider } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RegisterSchool from "./sections/RegisterSchool";
import SchoolList from "./sections/SchoolList";
import { getAllScholls } from "redux/actions/notesAction";

const Schools = () => {
    const dispatch = useDispatch();
    const [actionCourse, setActionCourse] = useState("list");

    const handleChangeAction = (action) => {
        setActionCourse(action);
    }

    useEffect(async () => {
        await dispatch(getAllScholls());
    });

    return(
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                GESTION DE ESCUELAS
            </MKTypography>
            <Divider />
            {actionCourse === "list" && (<SchoolList onChangeAction={handleChangeAction} />)}
            {actionCourse === "register" && (<RegisterSchool onChangeAction={handleChangeAction} />)}
        </>
    )
}

export default Schools;