import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

// Mui icons
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import { ROOT_SERVER } from 'helpers/helperInit';
import { useDispatch, useSelector } from 'react-redux';
import MKTypography from 'components/MKTypography';
import { Grid } from '@mui/material';
import Students from './Students';
import Teachers from './Teachers';
import Courses from './Courses';
import Schools from './Schools';
import Notification from 'components/Notification';
import { closeNotifAction } from 'redux/actions/severalAction';
import Plan from './Plan';
import AcademicCicle from './AcademicCicle';
import Inscription from './Inscription';
import Notes from './Notes';
import { ID_INSTITUCION } from 'helpers/helperInit';
import { NOM_CORTO_INSTITUCION1 } from 'helpers/helperInit';
import { NOM_CORTO_INSTITUCION2 } from 'helpers/helperInit';
import { LOGO_INSTITUCION1 } from 'helpers/helperInit';
import { LOGO_INSTITUCION2 } from 'helpers/helperInit';

const drawerWidth = 240;
const widthToolBar = '150px';

const ListMenu = ({onShowPage, nomRol}) => {
    const [openCoursePlan, setOpenCoursePlan] = useState(false);
    const [openOpening, setOpenOpening] = useState(false);
    
    const handleClickCoursePlan = () => {
        setOpenCoursePlan(!openCoursePlan);
    };
    
    const handleClickOpening = () => {
        setOpenOpening(!openOpening);
    };

    const handleLoadPage = (pageName) => {
        onShowPage(pageName);
    }

    // const hasConn = sessionStorage.getItem('hasConn');
    // const jsonHasConn = JSON.parse(hasConn);
    // const nomRol = jsonHasConn.nom_rol;

    return(
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader" sx={{background: '#CCE6F1'}}>
                    Opciones del sistema
                </ListSubheader>
            }
        >
            {nomRol === "ADM.SISTEMA" && (
                <>

                <ListItemButton onClick={() => handleLoadPage("students")} >
                    <ListItemIcon><PeopleOutlineIcon /></ListItemIcon>
                    <ListItemText 
                        primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                        primary="Alumnos" 
                    />
                </ListItemButton>
                <ListItemButton onClick={() => handleLoadPage("teachers")} >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText 
                        primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                        primary="Docentes" 
                    />
                </ListItemButton>
                <ListItemButton onClick={() => handleLoadPage("schools")} >
                    <ListItemIcon><HomeWorkIcon /></ListItemIcon>
                    <ListItemText 
                        primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                        primary="Escuelas" 
                    />
                </ListItemButton>
                <ListItemButton onClick={handleClickCoursePlan}>
                    <ListItemIcon><ArticleIcon /></ListItemIcon>
                    <ListItemText 
                        primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                        primary="Cursos - Plan" 
                    />
                    {openCoursePlan ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCoursePlan} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("courses")} >
                            <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                                primary="Cursos" 
                            />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("plan")} >
                            <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                                primary="Plan" 
                            />
                        </ListItemButton>
                    </List>
                </Collapse>
                </>    
            )}
            <ListItemButton onClick={handleClickOpening}>
                <ListItemIcon><MenuOpenIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Admision" 
                />
                {openOpening ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openOpening} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {nomRol === "ADM.SISTEMA" && (
                        <>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("opening")} >
                            <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                                primary="Aperturas" 
                            />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("inscription")} >
                            <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                                primary="Inscripción" 
                            />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("notes-admin")} >
                            <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                                primary="Notas" 
                            />
                        </ListItemButton>
                        </>
                    )}
                    {nomRol === "PROFESOR" && (
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("notes-teacher")} >
                            <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                                primary="Notas" 
                            />
                        </ListItemButton>
                    )}
                    {nomRol === "ALUMNO" && (
                        <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("notes-student")} >
                            <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                                primary="Notas" 
                            />
                        </ListItemButton>
                    )}
                </List>
            </Collapse>
        </List>
    )
}

const Administrator = () => {
    const dispatch = useDispatch();
    const reducerGeneralData = "generalDataReducer";
    const reducerNotif = "notifReducer";

    const hasConn = sessionStorage.getItem('hasConn');
    const jsonHasConn = JSON.parse(hasConn);
    const nomRol = jsonHasConn.nom_rol;
    let initialView = "students";
    if(nomRol === "PROFESOR") {
        initialView = "notes-teacher";
    }
    if(nomRol === "ALUMNO") {
        initialView = "notes-student";
    }

    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const messageNotif = useSelector(state => state[reducerNotif].notif_msg);
    const variantNotif = useSelector(state => state[reducerNotif].notif_variant);
    const typeNotif = useSelector(state => state[reducerNotif].notif_type);

    const [page, setPage] = useState(initialView);

    const logoCompany = ROOT_SERVER + "/static/images/logo/" + (ID_INSTITUCION === 1 ? LOGO_INSTITUCION1 : LOGO_INSTITUCION2);

    const closeNotif = () => dispatch(closeNotifAction());

    const handleShowPage = (pageLoad) => {
        setPage(pageLoad);
    }

    useEffect(() => {
        document.title = ID_INSTITUCION === 1 ? NOM_CORTO_INSTITUCION1 : NOM_CORTO_INSTITUCION2;
    });
    console.log("data_general_data...:", data_general_data);

    return(
        <>
        <Notification close={() => closeNotif()} message={messageNotif} notiftype={typeNotif} notifvariant={variantNotif} />
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
                >
                <Toolbar className='bg-secondary' style={{height: '150px'}}>
                    <Box style={{width: '100%', height: '150px', display: 'block'}}>
                        <Grid container>
                            <Grid item xs={12} pb={12}>
                                <DefaultNavbar showMenuUser />
                            </Grid>
                            <Grid item xs={12}>
                                <MKTypography
                                    color='white'
                                    sx={{
                                        fontSize: '20px',
                                        fontFamily: 'Georgia'
                                    }}
                                    align='center'
                                >
                                    {data_general_data.length > 0 ? data_general_data[0].nombre_institucion : ""}
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        background: '#c5a0cb'
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar className='bg-secondary' style={{height: '150px'}}>
                    <img src={logoCompany} width={95}></img>
                </Toolbar>
                <Divider />
                <ListMenu onShowPage={handleShowPage} nomRol={nomRol} />
            </Drawer>
            <Box
                component="main"
                sx={{ 
                    flexGrow: 1, 
                    // bgcolor: 'background.default', 
                    background: "#FFF",
                    p: 3 
                }}
                mt={20}
            >
                {page === "students" &&(<Students />)}
                {page === "teachers" &&(<Teachers />)}
                {page === "courses" &&(<Courses />)}
                {page === "schools" &&(<Schools />)}
                {page === "plan" &&(<Plan />)}
                {page === "opening" &&(<AcademicCicle />)}
                {page === "inscription" &&(<Inscription />)}
                {page === "notes-admin" &&(<Notes rol={"notes-admin"} />)}
                {page === "notes-teacher" &&(<Notes rol={"notes-teacher"} />)}
                {page === "notes-student" &&(<Notes rol={"notes-student"} />)}
                {/* <Typography paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien faucibus et molestie ac.

                Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography> */}
            </Box>
        </Box>
        </>
    )
}

export default Administrator;