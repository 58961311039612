import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Reducer
import { getGeneralData } from "redux/actions/generalDataAction";

import DashboardAdmin from "pages/LandingPages/DashboardAdm";

export default function DashboardAdminPage() {
  const dispatch = useDispatch();
  useEffect(async () => {
    // await dispatch(getGeneralData());
  });

  return <DashboardAdmin />;
}
