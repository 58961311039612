import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Card, Button, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { set_one_course_action } from "redux/actions/notesAction";
import ModuleSelect from "../../Module/Sections/ModuleSelect";
import MKTypography from "components/MKTypography";
import CicleSelect from "../../Module/Sections/CicleSelect";
import { DATA_COURSE_TYPE } from "helpers/helperInit";

const CoursesListCheck = ({onActionList, onSetCreditos, onSetModule, onSetCicle, actionList, dataAssigned, reload}) => {
    const dispatch = useDispatch();
    const reducerCourse = "courseReducer";
    const reducerModule = "moduleReducer";
    const reducerCicle = "cicleReducer";
    const data_courses = useSelector(state => state[reducerCourse].data_courses);
    const data_modules = useSelector(state => state[reducerModule].data_modules);
    const data_cicles = useSelector(state => state[reducerCicle].data_cicles);
    // const [dataCourses, setDataCourses] = useState(data_courses);
    const [coursesDisponible, setCoursesDisponible] = useState([]);
    const [coursesOrigDisponible, setCoursesOrigDisponible] = useState([]);
    const [coursesSelected, setCoursesSelected] = useState([]);
    const [valueFindCourse, setValueFindCourse] = useState("");
    const [valuesCourse, setValuesCourse] = useState({
        creditos: 0,
        fk_modulo: 0,
        tipo: '1'
    });

    const typeCourseOpc = DATA_COURSE_TYPE.filter(item => item.id_tipo === 2)[0].nombre;

    const handleSendAssigned = () => {
        onActionList(coursesSelected);
        setCoursesSelected([]);
    }

    const handleSelectCourse = (e, course) => {
        if(!!e.target.checked) {
            coursesSelected.push(course);
        } else {
            const newList = coursesSelected.filter(item => parseInt(item.id_curso) !== parseInt(course.id_curso));
            setCoursesSelected(newList);            
        }
    }

    const handleChangeCredit = (e, course) => {
        setValuesCourse({...valuesCourse, [e.target.name]: e.target.value});
        const valCred = e.target.value;
        onSetCreditos(course, valCred);
    }
    
    const handleChangeModulo = (module, course) => {
        onSetModule(course, module.id_modulo);
    }
    
    const handleChangeCicle = (cicle, course) => {
        onSetCicle(course, cicle.id_ciclo);
    }

    const handleFindCourse = (e) => {
        if(e.target.value === "") {
            setCoursesDisponible(coursesOrigDisponible);
        } else {
            let newList = coursesOrigDisponible.filter(item => 
                item.curso.toUpperCase().includes(e.target.value.toUpperCase()));
            setCoursesDisponible(newList);
        }
        setValueFindCourse(e.target.value);
    }

    const initData = () => {
        if(data_courses !== null) {
            const newList = [];
            data_courses.map(item => {
                let newCourse = dataAssigned.filter(l => parseInt(l.id_curso) === parseInt(item.id_curso));
                if(newCourse.length === 0) {
                    newList.push(item);
                }
                else {
                    if(newCourse[0].tipo === 2) {
                        newList.push(item);
                    }
                }
            });
            setCoursesOrigDisponible(newList);
            setCoursesDisponible(newList);
        }
    }

    useEffect(() => {
        initData();
    }, [data_courses, dataAssigned, reload]);

    return(
        <>
        {actionList === "asignar" && (
            <>
            <Card sx={{background: "#FFF", border: '1px solid #CCC'}}>
                <Grid container>
                    <Grid item xs={12} lg={8} mt={1} textAlign='center'>
                        <MKTypography sx={{fontSize: '15px', color:'#8D8D8D'}} fontWeight='bold'>DISPONIBLES</MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={4} textAlign='right'>
                        <Button variant='contained' 
                            style={{background: '#FFF', color: '#000', marginRight: '5px'}}
                            onClick={handleSendAssigned} 
                        >
                            ASIGNAR
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9} px={2}>
                        <TextField 
                            variant="standard"
                            name="valueFindCourse"
                            value={valueFindCourse}
                            placeholder="Buscar..."
                            onChange={handleFindCourse}
                            color='text'
                            sx={{ padding: '5px'}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MKTypography mt={1} textAlign='center' fontWeight='bold' sx={{fontSize: '12px', background: '#DDE2FB'}}>{typeCourseOpc !== null ? typeCourseOpc : ""}</MKTypography>
                    </Grid>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow hover sx={{borderTop: '1px solid #CCC'}}>
                            <TableCell align="right"></TableCell>
                            <TableCell align="left">Curso</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {coursesDisponible && coursesDisponible
                        .map((course, index) => {
                            return(
                                <TableRow key={"course" + course.id_curso} hover>
                                    <TableCell align='right' style={{verticalAlign: 'top'}}>
                                        <label style={{paddingRight: '20px'}}>
                                            <input type="checkbox" className="filled-in" onChange={(e) => handleSelectCourse(e, course)} id="chkGetAll" />
                                            {/* <span>Mostrar Todos</span> */}
                                        </label>
                                    </TableCell>
                                    <TableCell align="left" style={{fontSize: '13px', background: course.tipo === 2 ? '#DDE2FB' : "#FFF"}}>
                                        {course.curso}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Card>
            </>
        )}
        {actionList === "desasignar" && (
            <>
            <Card sx={{background: "#FFF", border: '1px solid #CCC'}}>
                <Grid container>
                    <Grid item xs={12} lg={4} textAlign='left'>
                        <Button variant='contained' 
                            style={{background: '#FFF', color: '#000', marginRight: '5px'}}
                            onClick={handleSendAssigned} 
                        >
                            DESASIGNAR
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={8} mt={1}>
                        <MKTypography sx={{fontSize: '15px', color:'#8D8D8D'}} fontWeight='bold'>CURSOS ASIGNADOS</MKTypography>
                    </Grid>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow hover sx={{borderTop: '1px solid #CCC'}}>
                            <TableCell align="right"></TableCell>
                            <TableCell align="left">Curso</TableCell>
                            <TableCell align="left">Ciclo</TableCell>
                            <TableCell align="left">Modulo</TableCell>
                            <TableCell align="left">Creditos</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataAssigned && dataAssigned
                        .map(course => {
                            return(
                                <TableRow key={"course" + course.id_curso} hover>
                                    <TableCell align='right'>
                                        <label style={{paddingRight: '20px'}}>
                                            <input type="checkbox" className="filled-in" onChange={(e) => handleSelectCourse(e, course)} id="chkGetAll" />
                                        </label>
                                    </TableCell>
                                    <TableCell align="left" style={{fontSize: '13px'}}>{course.curso}</TableCell>
                                    <TableCell align="left">
                                        <CicleSelect dataCicles={data_cicles} onSelectCicle={(val) => handleChangeCicle(val, course)} cicleSelected={course.fk_ciclo} />
                                    </TableCell>
                                    <TableCell align="left">
                                        <ModuleSelect dataModules={data_modules} onSelectModule={(val) => handleChangeModulo(val, course)} moduleSelected={course.fk_modulo} />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField 
                                            variant="standard"
                                            type="number"
                                            name="creditos"
                                            value={course.creditos}
                                            placeholder="Creditos"
                                            onChange={(e) => handleChangeCredit(e, course)}
                                            color='text'
                                            sx={{ padding: '5px'}}
                                            fullWidth
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Card>
            </>
        )}
        </>
    );
}

export default CoursesListCheck;