import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { set_one_course_action } from "redux/actions/notesAction";
import MKTypography from "components/MKTypography";
import ConfirmationDialog from "components/ConfirmationDialog";
import { saveDeleteCourse } from "redux/actions/notesAction";
import { openNotifAction } from "redux/actions/severalAction";
import { getAllCourses } from "redux/actions/notesAction";
import SchoolSelect from "../../Schools/sections/SchoolSelect";

const CourseList = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerSchool = "schoolReducer";
    const reducerCourse = "courseReducer";
    const data_schools = useSelector(state => state[reducerSchool].data_schools);
    const data_courses = useSelector(state => state[reducerCourse].data_courses);
    const [dataCourses, setDataCourses] = useState(data_courses);
    const [defaultSchool, setDefaultSchool] = useState(0);
    const [deleteCourse, setDeleteCourse] = useState({
        id_curso: 0
    });
    const [valorBusq, setValorBusq] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleFindCourse = (e) => {
        // let newList = [];
        let newList = data_courses;
        if(e.target.value !== "") {
        //     newList = data_courses;
        // } else {
            newList = newList.filter(item => item.curso.toUpperCase().includes(e.target.value.toUpperCase()));
        }
        if(defaultSchool > 0) {
            newList = newList.filter(item => parseInt(item.id_escuela) === parseInt(defaultSchool));
        }
        setDataCourses(newList);
        setValorBusq(e.target.value);
    }

    const handleSelectSchool = (school) => {
        setDefaultSchool(school.id_escuela);
        let newList = data_courses;
        if(valorBusq !== "") {
            newList = newList.filter(item => item.curso.toUpperCase().includes(valorBusq.toUpperCase()));
        }
        newList = newList.filter(item => parseInt(item.id_escuela) === parseInt(school.id_escuela));
        setDataCourses(newList);
        // setValues({...values, id_escuela: school.id_escuela});
    }

    const handleSetAction = () => {
        dispatch(set_one_course_action(null));
        onChangeAction("register");
    }

    const handleEditCourse = async (course) => {
        await dispatch(set_one_course_action(course));
        onChangeAction("register");
    }

    const handleDeleteCourse = (course) => {
        setDeleteCourse({
            id_curso: parseInt(course.id_curso)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Curso",
            content: "¿Seguro de Eliminar Curso seleccionado?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataCourse = {
                "id_curso": deleteCourse.id_curso
            };
			const rptaSave = await saveDeleteCourse(newDataCourse);
            if(rptaSave.success) {
                dispatch(openNotifAction(["Eliminación exitosa!!!", "success", 'filled']));
                await dispatch(getAllCourses());
            } else {
                dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    const initData = () => {
        setDataCourses(data_courses);
    }

    useEffect(() => {
        initData();
    }, [data_courses]);


    return(
        <>
            <Box>
                {/* <MKTypography className='color-secondary' fontWeight='bold'>
                    Listado
                </MKTypography>
                <Divider /> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <TextField 
                            variant="standard"
                            name="valorBusq"
                            value={valorBusq}
                            placeholder="Buscar..."
                            onChange={handleFindCourse}
                            color='text'
                            sx={{ padding: '5px'}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <SchoolSelect dataSchools={data_schools} onSelectSchool={handleSelectSchool} schoolSelected={defaultSchool} />
                    </Grid>
                    <Grid item xs={12} lg={3} textAlign='right'>
                        <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSetAction} >REGISTRAR NUEVO</Button>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow hover>
                        <TableCell align="left">Escuela</TableCell>
                        <TableCell align="left">Curso</TableCell>
                        <TableCell align="right">...</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataCourses && dataCourses
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(course => {
                        return(
                            <TableRow key={"course" + course.id_curso} hover>
                                <TableCell align="left">{course.escuela}</TableCell>
                                <TableCell align="left">{course.curso}</TableCell>
                                <TableCell align='right'>
                                    <Tooltip title='Editar'>
                                        <IconButton color='text' onClick={() => handleEditCourse(course)} ><EditIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                    <Tooltip title='Anular'>
                                        <IconButton color='text' onClick={() => handleDeleteCourse(course)}><DeleteIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                rowsPerPageOptions={[5,10,50,100]}
                page={page}
                rowsPerPage={rowsPerPage}
                count={dataCourses ? dataCourses.length : 0 }
                labelRowsPerPage=''
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationDialog 
                open={stateConfirmDialog.open}
                title={stateConfirmDialog.title}
                content={stateConfirmDialog.content}
                onClose={handleCloseConfirmDialog}
            />
        </>
    );
}

export default CourseList;