import axios from "axios";
import { MESSAGE_NOT_CONNECT_SERVER } from "helpers/helperInit";
import { URL_SERVICE, getUserAuth } from "helpers/helperInit";
import { typesPlan } from "redux/constants/type";
import { typesMatricula } from "redux/constants/type";
import { typesModules } from "redux/constants/type";
import { typesCalificationType } from "redux/constants/type";
import { typesCicles } from "redux/constants/type";
import { typesClassroom } from "redux/constants/type";
import { typesPlanCurso } from "redux/constants/type";
import { typesSchools } from "redux/constants/type";
import { typesTeachers } from "redux/constants/type";
import { typesCourses } from "redux/constants/type";
import { typesStudents } from "redux/constants/type";
import { openNotifAction } from "./severalAction";

const validatorResponseService = (dispatch, requireAuth, data, entity, action) => {
    if(data.success !== undefined) {
        if(requireAuth) {
            if (!data.isAuthenticated) {
                window.location = "/";
            }    
        }
        if (data.success) {
            if(entity === "student") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_students_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Estudiante insertado con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "teacher") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_teachers_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "course") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_courses_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "plan") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_planes_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Plan insertado con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Plan actualizado con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Plan eliminado con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "plan_curso") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_plan_cursos_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Plan insertado con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Plan actualizado con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Plan eliminado con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "school") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_schools_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "matricula") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_matriculas_action(data.data));
                        return;
                    default:
                        return
                }
            }
            if(entity === "classroom") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_classrooms_action(data.data));
                        return;
                    default:
                        return
                }
            }
            if(entity === "module") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_modules_action(data.data));
                        return;
                    default:
                        return
                }
            }
            if(entity === "cicles") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_cicles_action(data.data));
                        return;
                    default:
                        return
                }
            }
            if(entity === "calification-types") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_calification_types_action(data.data));
                        return;
                    default:
                        return
                }
            }
        } else {
            dispatch(openNotifAction([data.msg_rpta, "error", 'filled']));
        }
    } else {
        dispatch(openNotifAction([MESSAGE_NOT_CONNECT_SERVER, "error", 'filled']));
    }
}

// Students actions
export const getAllStudents = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "student";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "alumno/getAlumnos/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewStudent = async (dataStudent) => {
    let rptaReturn = [];
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "student";
    const action = "insert";
    const res = await axios(URL_SERVICE + "alumno/insertUpdateAlumno/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataStudent
    });
    const data = (await res).data;
    return data.data[0];
}

export const saveDeleteStudent = async (dataStudent) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "alumno/deleteAlumno/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "DELETE",
        data: dataStudent
    });
    const data = (await res).data;
    return data;
}

export const get_all_students_action = (data) => {
    return {
        type: typesStudents.read,
        payload: data
    }
}

export const set_one_student_action = (data) => {
    return {
        type: typesStudents.setOneStudent,
        payload: data
    }
}

// End Students actions

// Teachers actions
export const getAllTeachers = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "teacher";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "profesor/getProfesores/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewTeacher = async (dataTeacher) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "student";
    const action = "insert";
    const res = await axios(URL_SERVICE + "profesor/insertUpdateProfesor/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataTeacher
    });
    const data = (await res).data;
    return data.data[0];
}

export const saveDeleteTeacher = async (dataTeacher) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "profesor/deleteProfesor/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "DELETE",
        data: dataTeacher
    });
    const data = (await res).data;
    return data;
}

export const get_all_teachers_action = (data) => {
    return {
        type: typesTeachers.read,
        payload: data
    }
}

export const set_one_teacher_action = (data) => {
    return {
        type: typesTeachers.setOneTeacher,
        payload: data
    }
}
// End Teachers actions

// Courses actions
export const getAllCourses = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "course";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "curso/getCursos/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const getCoursesBySchool = (idSchool) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "course";
    const action = "get-all";
    const param = JSON.stringify({"id_escuela": idSchool});
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "curso/getCursosXEscuela/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "POST",
            data: param
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewCourse = async (dataCourse) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "student";
    const action = "insert";
    const res = await axios(URL_SERVICE + "curso/insertUpdateCurso/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataCourse
    });
    const data = (await res).data;
    return data;
}

export const saveDeleteCourse = async (dataCourse) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "curso/deleteCurso/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "DELETE",
        data: dataCourse
    });
    const data = (await res).data;
    return data;
}

export const get_all_courses_action = (data) => {
    return {
        type: typesCourses.read,
        payload: data
    }
}

export const set_one_course_action = (data) => {
    return {
        type: typesCourses.setOneCourse,
        payload: data
    }
}
// End Courses actions

// Schoold actions
export const getAllScholls = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "school";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "escuela/getEscuelas/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewSchool = async (dataShool) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "student";
    const action = "insert";
    const res = await axios(URL_SERVICE + "escuela/insertUpdateEscuela/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataShool
    });
    const data = (await res).data;
    return data;
}

export const saveDeleteSchool = async (dataSchool) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "escuela/deleteEscuela/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "DELETE",
        data: dataSchool
    });
    const data = (await res).data;
    return data;
}

export const get_all_schools_action = (data) => {
    return {
        type: typesSchools.read,
        payload: data
    }
}

export const set_one_school_action = (data) => {
    return {
        type: typesSchools.setOneSchool,
        payload: data
    }
}
// End Schoold actions

// Plan actions
export const getAllPlanes = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "plan";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "plan/getPlanes/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewPlan = async (dataPlan) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "plan/insertUpdatePlan/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataPlan
    });
    const data = (await res).data;
    return data;
}

export const saveDeletePlan = async (dataPlan) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "plan/deletePlan/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "DELETE",
        data: dataPlan
    });
    const data = (await res).data;
    return data;
}

export const getAllPlanCursos = (idPlan) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "plan_curso";
    const action = "get-all";
    const dataPlanCurso = {id_plan: idPlan};
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "plan/getPlanCurso/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "POST",
            data: idPlan
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewPlanCurso = async (dataPlanCurso) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    console.log("dataPlanCurso....:", dataPlanCurso)
    const res = await axios(URL_SERVICE + "plan/insertPlanCurso/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataPlanCurso
    });
    const data = (await res).data;
    return data;
}

export const saveEditPlanCurso = async (dataPlanCurso) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "plan/updatePlanCurso/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataPlanCurso
    });
    const data = (await res).data;
    return data;
}

export const saveDeletePlanCurso = async (dataPlanCurso) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "plan/deletePlanCurso/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataPlanCurso
    });
    const data = (await res).data;
    return data;
}

export const get_all_planes_action = (data) => {
    return {
        type: typesPlan.read,
        payload: data
    }
}

export const set_one_plan_action = (data) => {
    return {
        type: typesPlan.setOnePlan,
        payload: data
    }
}

export const get_all_plan_cursos_action = (data) => {
    return {
        type: typesPlanCurso.read,
        payload: data
    }
}
// End Plan actions

// Matricula actions
export const getAllMatricula = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "matricula";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "matricula/getMatriculas/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const get_all_matriculas_action = (data) => {
    return {
        type: typesMatricula.read,
        payload: data
    }
}

export const set_one_matricula_action = (data) => {
    return {
        type: typesMatricula.setOneMatricula,
        payload: data
    }
}
// End Matricula actions

// Matricula actions
export const getAllClassrooms = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "classroom";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "aula/getAulas/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const getAllCoursesSchool = (idSchool) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "course";
    const action = "get-all";
    const dataSchool = JSON.stringify({"id_escuela": parseInt(idSchool)});
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "escuela/getCursosEscuela/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "POST",
            data: dataSchool
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const getOtherAllCoursesSchool = async (idSchool) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "course";
    const action = "get-all";
    const dataSchool = JSON.stringify({"id_escuela": parseInt(idSchool)});
    const res = await axios(URL_SERVICE + "escuela/getCursosEscuela/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataSchool
    });
    const data = (await res).data;
    return data;    
}

export const getOtherAllStudentClassroom = async (idClassroom) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "course";
    const action = "get-all";
    const dataClassroom = JSON.stringify({"id_aula": parseInt(idClassroom)});
    const res = await axios(URL_SERVICE + "aula/getAlumnosxAula/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataClassroom
    });
    const data = (await res).data;
    return data;    
}

export const saveNewMatricula = async (dataMatricula) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "student";
    const action = "insert";
    const res = await axios(URL_SERVICE + "matricula/insertUpdateMatricula/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataMatricula
    });
    const data = (await res).data;
    return data;
}

export const saveNewClassroom = async (dataClassroom) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "student";
    const action = "insert";
    const res = await axios(URL_SERVICE + "aula/insertUpdateAula/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataClassroom
    });
    const data = (await res).data;
    return data;
}

export const saveDeleteClassroom = async (dataClassroom) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "aula/deleteAula/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "DELETE",
        data: dataClassroom
    });
    const data = (await res).data;
    return data;
}

export const saveDeleteStudentClassroom = async (dataClassroom) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "aula/deleteAulaAlumno/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "DELETE",
        data: dataClassroom
    });
    const data = (await res).data;
    return data;
}

export const getCoursesAvailable = async (idStudent) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const param = JSON.stringify({ "id_alumno": parseInt(idStudent)});
    const res = await axios(URL_SERVICE + "aula/getCursosDisponibles/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: param
    });
    const data = (await res).data;
    return data;
}

export const getCoursesInscribed = async (idStudent) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const param = JSON.stringify({ "id_alumno": parseInt(idStudent)});
    const res = await axios(URL_SERVICE + "aula/getCursosInscritos/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: param
    });
    const data = (await res).data;
    return data;
}

export const saveStudentClassroom = async (dataStudenClassroom) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "aula/insertUpdateAulaAlumno/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "post",
        data: dataStudenClassroom
    });
    const data = (await res).data;
    return data;
}

export const get_all_classrooms_action = (data) => {
    return {
        type: typesClassroom.read,
        payload: data
    }
}

export const set_one_classroom_action = (data) => {
    return {
        type: typesClassroom.setOneClassroom,
        payload: data
    }
}
// End Matricula actions

// Modules actions
export const getAllModules = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "module";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "aula/getModulos/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const get_all_modules_action = (data) => {
    return {
        type: typesModules.read,
        payload: data
    }
}
// End Modules actions

// Modules actions
export const getAllCicles = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "cicles";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "aula/getCiclos/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveUpdateNota = async (dataNote) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "aula/updateNota/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataNote
    });
    const data = (await res).data;
    return data;
}

export const getNotasByStudentClassroom = async (idStudentClassroom) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const dataParam = JSON.stringify({fk_alumno_aula: parseInt(idStudentClassroom)});
    const res = await axios(URL_SERVICE + "aula/getNotas/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataParam
    });
    const data = (await res).data;
    return data;
}

export const saveNewNota = async (dataNote) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "aula/insertNota/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataNote
    });
    const data = (await res).data;
    return data;
}

export const get_all_cicles_action = (data) => {
    return {
        type: typesCicles.read,
        payload: data
    }
}
// End Modules actions

// Calification types actions
export const getAllCalificationTypes = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "calification-types";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "aula/getTipoCalificacion/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const get_all_calification_types_action = (data) => {
    return {
        type: typesCalificationType.read,
        payload: data
    }
}
// End Calification types