import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import MKTypography from "components/MKTypography";
import { Box, Card, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import SchoolSelect from "../../Schools/sections/SchoolSelect";
import { openNotifAction } from "redux/actions/severalAction";
import CicleSelect from "../../Module/Sections/CicleSelect";
import { getOtherAllStudentClassroom } from "redux/actions/notesAction";
import { saveUpdateNota } from "redux/actions/notesAction";
import Edit from "@mui/icons-material/Edit";
import { DATA_CALIFICACION } from "helpers/helperInit";
import { saveNewNota } from "redux/actions/notesAction";
import { getNotasByStudentClassroom } from "redux/actions/notesAction";
import { ID_CALI_FINAL } from "helpers/helperInit";
import { ID_CALI_PARTIAL } from "helpers/helperInit";
import { ID_CALI_WORK } from "helpers/helperInit";
import { ID_CALI_PRACTICE } from "helpers/helperInit";
import { ID_INSTITUCION } from "helpers/helperInit";

const RegisterNotes = () => {
    const dispatch = useDispatch();
    const reducerSchool = "schoolReducer";
    const reducerClassroom = "classroomReducer";
    const reducerMatricula = "matriculaReducer";
    const reducerCicle = "cicleReducer";
    const reducerCalificationType = "calificationTypeReducer";
    const data_schools = useSelector(state => state[reducerSchool].data_schools);
    const data_cicles = useSelector(state => state[reducerCicle].data_cicles);
    const data_matriculas = useSelector(state => state[reducerMatricula].data_matriculas);
    const data_classrooms = useSelector(state => state[reducerClassroom].data_classrooms);
    const data_calification_types = useSelector(state => state[reducerCalificationType].data_calification_types);
    const [oneMatricula, setOneMatricula] = useState(null);
    const [loadPage, setLoadPage] = useState(false);
    const [dataOriginClassroom, setDataOriginClassroom] = useState(null);
    const [dataClassInscribed, setDataClassInscribed] = useState(null);
    const [dataStudentsClassroom, setDataStudentsClassroom] = useState(null);
    const [oneClassroom, setOneClassroom] = useState(null);
    const [expanClassroom, setExpanClassroom] = useState(true);
    const [valorBusqClassroom, setValorBusqClassroom] = useState("");
    const [defaultSchool, setDefaultSchool] = useState(0);
    const [defaultCicle, setDefaultCicle] = useState(0);
    const [valuesStudent, setValuesStudent] = useState({
        nota: 0
    });
    const [valuesCalifNotes, setValuesCalifNotes] = useState({
        examen_final: 0,
        examen_parcial: 0,
        trabajos: 0,
        practicas: 0
    });
    const [classroomSelect, setClassroomSelect] = useState(null);
    const [notesThisStudent, setNotesThisStudent] = useState(null);
    const [openDialogNotes1, setOpenDialogNotes1] = useState(false);
    const [openDialogNotes2, setOpenDialogNotes2] = useState(false);
    // const [noteExaFinal, setNoteExaFinal] = useState(0);
    // const [noteExaParcial, setNoteExaParcial] = useState(0);
    // const [noteTrabajo, setNoteTrabajo] = useState(0);
    // const [notePracticas, setNotePracticas] = useState(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const hasConn = sessionStorage.getItem('hasConn');
    const jsonHasConn = JSON.parse(hasConn);
    const idPerson = jsonHasConn.idPersona;

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleExpandClassroom = () => {
        setExpanClassroom(!expanClassroom);
    };

    const getSatudensThisClassroom = async (idStudentClassroom) => {
        console.log("oneClassroom....", idStudentClassroom)
        const rptaReturn = await getOtherAllStudentClassroom(idStudentClassroom);
        const rptaData = rptaReturn.data;
        setDataStudentsClassroom(rptaData);
        console.log(rptaReturn);
    }

    const handleSelectClassroom = async (classroom) => {
        await setOneClassroom(classroom);
        setExpanClassroom(!expanClassroom);
        console.log("classroom.id_aula....", classroom.id_aula)
        getSatudensThisClassroom(classroom.id_aula);
    }

    const handleFindClassroom = async (e) => {
        if(e.target.value === "") {
            setDataClassInscribed(dataOriginClassroom);
        } else {
            let newList = dataOriginClassroom.filter(item => 
                item.curso[0].curso.toUpperCase().includes(e.target.value.toUpperCase()) 
            );
            setDataClassInscribed(newList);
        }
        if(defaultSchool > 0) {
            newList = await newList.filter(item => parseInt(item.escuela[0].id_escuela) === parseInt(defaultSchool));        
        }
        if(defaultCicle > 0) {
            newList = await newList.filter(item => parseInt(item.id_ciclo) === parseInt(defaultCicle));            
        }
        setValorBusqClassroom(e.target.value);
    }

    const handleSelectSchool = async (school) => {
        setDefaultSchool(school.id_escuela);
        let newList = dataOriginClassroom;
        if(valorBusqClassroom !== "") {
            newList = await dataOriginClassroom.filter(item => item.curso[0].curso.toUpperCase().includes(valorBusqClassroom.toUpperCase()) );
        }
        if(defaultCicle > 0) {
            newList = await newList.filter(item => parseInt(item.id_ciclo) === parseInt(defaultCicle));            
        }
        newList = await newList.filter(item => parseInt(item.escuela[0].id_escuela) === parseInt(school.id_escuela));        
        setDataClassInscribed(newList);
    }

    const handleChangeCicle = async (cicle) => {
        setDefaultCicle(cicle.id_ciclo);
        let newList = dataOriginClassroom;
        if(valorBusqClassroom !== "") {
            newList = await dataOriginClassroom.filter(item => item.curso[0].curso.toUpperCase().includes(valorBusqClassroom.toUpperCase()) );
        }
        if(defaultSchool > 0) {
            newList = await newList.filter(item => parseInt(item.escuela[0].id_escuela) === parseInt(defaultSchool));        
        }
        newList = await newList.filter(item => parseInt(item.id_ciclo) === parseInt(cicle.id_ciclo));        
        setDataClassInscribed(newList);
    }

    const handleChangeNote = (e, student) => {
        dataStudentsClassroom.map(item => {
            if(parseInt(item.id_alumno_aula) === parseInt(student.id_alumno_aula)) {
                item.nota = e.target.value;
            }
        });
        setValuesStudent({...valuesStudent, [e.target.name]: e.target.value});
    }

    const handleSaveNotes = async () => {
        let flgError = false;
        for(var i = 0; i < dataStudentsClassroom.length; i++) {
            if(dataStudentsClassroom[i].nota === "") {
                dataStudentsClassroom[i].nota = 0
            } else {
                dataStudentsClassroom[i].nota = parseInt(dataStudentsClassroom[i].nota);
            }
            let newElement = {
                "id_alumno_aula": dataStudentsClassroom[i].id_alumno_aula,
                "nota": dataStudentsClassroom[i].nota
            }
            const rptaSave = await saveUpdateNota(newElement);
            if(!rptaSave.success) {
                flgError = true;
            }    
        }
        if(!flgError) {
            dispatch(openNotifAction(["Registro exitoso!!!", "success", 'filled']));
        } else {
            dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
        }
        console.log(dataStudentsClassroom);
    }

    const initData = async () => {
        if(data_matriculas !== null) {
            if(data_matriculas.length > 0) {
                const matrOpen = data_matriculas.filter(item => item.estadoMatricula === 1);
                if(matrOpen.length > 0) {
                    const newList = data_classrooms.filter(item => parseInt(item.id_matricula) === parseInt(matrOpen[0].id_matricula) && parseInt(item.profesor[0].fk_persona) === parseInt(idPerson));
                    await setOneMatricula(matrOpen[0]);
                    setDataClassInscribed(newList);
                    setDataOriginClassroom(newList);
                }
            }
        }
        setLoadPage(true);  
    }

    const handleOpenNotasDetails = async (studentClassroom) => {
        setClassroomSelect(studentClassroom);
        setValuesCalifNotes([]);
        const dataResult = await getNotasByStudentClassroom(studentClassroom.id_alumno_aula);
        const notesStudent = dataResult.data;
        console.log("notesStudent....", notesStudent)
        let exaFinal = 0;
        let exaParcial = 0;
        let trabajos = 0;
        let practicas = 0;
        await notesStudent.map(item => {
            if(parseInt(item.fk_tipocalificacion) === ID_CALI_FINAL) {
                exaFinal = item.nota;
            }
            if(parseInt(item.fk_tipocalificacion) === ID_CALI_PARTIAL) {
                exaParcial = item.nota;
            }
            if(parseInt(item.fk_tipocalificacion) === ID_CALI_WORK) {
                trabajos = item.nota;
            }
            if(parseInt(item.fk_tipocalificacion) === ID_CALI_PRACTICE) {
                practicas = item.nota;
            }
        });
        setValuesCalifNotes({
            examen_final: exaFinal,
            examen_parcial: exaParcial,
            trabajos: trabajos,
            practicas: practicas
        })
        console.log("valuesCalifNotes....", valuesCalifNotes)
        let newList = [];
        if(ID_INSTITUCION === 1){
            setOpenDialogNotes1(true);
        } else {
            setOpenDialogNotes2(true);
        }
    }

    const handleChangeCalifNote = (e) => {
        setValuesCalifNotes({...valuesCalifNotes, [e.target.name]: e.target.value});
    }

    const handleChangeNoteExaFinal = (e) => {
        setNoteExaFinal([e.target])
    }

    const handleSetNotes = async () => {
        let newElement = {
            fk_alumno_aula: classroomSelect.id_alumno_aula,
            fk_tipocalificacion: ID_CALI_FINAL,
            nota: valuesCalifNotes.examen_final
        }
        let rptaSave = await saveNewNota(newElement);

        newElement = {
            fk_alumno_aula: classroomSelect.id_alumno_aula,
            fk_tipocalificacion: ID_CALI_PARTIAL,
            nota: valuesCalifNotes.examen_parcial
        }
        rptaSave = await saveNewNota(newElement);
        
        newElement = {
            fk_alumno_aula: classroomSelect.id_alumno_aula,
            fk_tipocalificacion: ID_CALI_WORK,
            nota: valuesCalifNotes.trabajos
        }
        rptaSave = await saveNewNota(newElement);
        
        newElement = {
            fk_alumno_aula: classroomSelect.id_alumno_aula,
            fk_tipocalificacion: ID_CALI_PRACTICE,
            nota: valuesCalifNotes.practicas
        }
        rptaSave = await saveNewNota(newElement);

        getSatudensThisClassroom(oneClassroom.id_aula)
        setOpenDialogNotes1(false);
        setOpenDialogNotes2(false);
    }

    const handleCloseDialog = () => {
        setOpenDialogNotes1(false);
        setOpenDialogNotes2(false);
    };

    useEffect(() => {
        initData();
    }, [loadPage, data_matriculas, data_classrooms, valuesCalifNotes]);

    return(
        <>
        {!!loadPage ? (
            <>
                {oneMatricula !== null && (
                    <>
                    <MKTypography display='inline' fontWeight='bold' sx={{fontSize: '15px'}}>
                        Matrícula: {oneMatricula.anio.toString() + " - " + oneMatricula.periodo}
                    </MKTypography>


                    <Divider />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Accordion expanded={expanClassroom} onChange={handleExpandClassroom} sx={{border: '1px solid #CCC'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            {oneClassroom !== null ? (
                                                <MKTypography display='inline' sx={{fontSize: '13px'}}>
                                                    <b>Curso:</b> {oneClassroom !== null ? oneClassroom.curso[0].curso + " / " + oneClassroom.escuela[0].escuela + " - " + oneClassroom.ciclo  + " CICLO" : ""}
                                                </MKTypography>
                                            ) : (
                                                <MKTypography display='inline' sx={{fontSize: '15px', fontStyle: 'italic', color: '#CCC'}}>
                                                    Seleccione Curso
                                                </MKTypography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <TextField 
                                                variant="standard"
                                                name="valorBusqClassroom"
                                                value={valorBusqClassroom}
                                                placeholder="Buscar curso..."
                                                onChange={handleFindClassroom}
                                                color='text'
                                                sx={{ padding: '5px'}}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <SchoolSelect dataSchools={data_schools} onSelectSchool={handleSelectSchool} schoolSelected={defaultSchool} />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <CicleSelect dataCicles={data_cicles} onSelectCicle={handleChangeCicle} cicleSelected={defaultCicle} />
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableBody>
                                            <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                                <TableCell align="left">Curso</TableCell>
                                                <TableCell align="left">Escuela</TableCell>
                                                <TableCell align="left">Ciclo</TableCell>
                                            </TableRow>
                                            {dataClassInscribed !== null && dataClassInscribed
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(classroom => {
                                                return(
                                                    <TableRow 
                                                        key={"classroom" + classroom.id_aula} 
                                                        hover 
                                                        sx={{cursor: 'pointer'}} 
                                                        onClick={() => handleSelectClassroom(classroom)}
                                                    >
                                                        <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                            {classroom.curso[0].curso}
                                                        </TableCell>
                                                        <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                            {classroom.escuela[0].escuela}
                                                        </TableCell>
                                                        <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                            {classroom.ciclo}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[5,10,50,100]}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    count={dataClassInscribed ? dataClassInscribed.length : 0 }
                                    labelRowsPerPage=''
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{border: '1px solid #CCC'}}>
                                <Table>
                                    <TableBody>
                                        <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                            <TableCell align="left">Alumno</TableCell>
                                            <TableCell align="center">Nota</TableCell>
                                            <TableCell align="center">
                                                {/* <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSaveNotes}>GUARDAR</Button> */}
                                            </TableCell>
                                        </TableRow>
                                        {dataStudentsClassroom !== null && dataStudentsClassroom.map((student, index) => {
                                            return(
                                                <TableRow 
                                                    key={"classroom" + student.id_alumno_aula} 
                                                    hover 
                                                >
                                                    <TableCell align="left" sx={{fontSize: '12px'}}>
                                                        {index + 1 + ") " + student.ape_paterno + " " + student.ape_materno + " " + student.nom_persona}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{fontSize: '15px'}}>
                                                        {/* <TextField 
                                                            variant="standard"
                                                            type="number"
                                                            name="nota"
                                                            value={student.nota}
                                                            placeholder="Nota"
                                                            onChange={(e) => handleChangeNote(e, student)}
                                                            inputProps={{ style: {textAlign: 'center'} }}
                                                            color='text'
                                                            sx={{ padding: '5px'}}
                                                            fullWidth
                                                        /> */}
                                                        {student.nota}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => handleOpenNotasDetails(student)} >
                                                            <Edit />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                    </Grid>
          
                    <Dialog onClose={handleCloseDialog} open={openDialogNotes1} maxWidth="xs" fullWidth>
                        <DialogContent>
                            <Grid container spacing={2} pt={5}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        variant="standard"
                                        label="Examen Final"
                                        name="examen_final"
                                        value={valuesCalifNotes.examen_final}
                                        inputProps={{ style: {textAlign: 'center'} }}
                                        onChange={handleChangeCalifNote}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        variant="standard"
                                        label="Examen Parcial"
                                        name="examen_parcial"
                                        value={valuesCalifNotes.examen_parcial}
                                        inputProps={{ style: {textAlign: 'center'} }}
                                        onChange={handleChangeCalifNote}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        variant="standard"
                                        label="Presentación y exposición de trabajo final"
                                        name="trabajos"
                                        value={valuesCalifNotes.trabajos}
                                        inputProps={{ style: {textAlign: 'center'} }}
                                        onChange={handleChangeCalifNote}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        variant="standard"
                                        label="Participación diaria(orales, tareas académicas, asistencia)"
                                        name="practicas"
                                        value={valuesCalifNotes.practicas}
                                        inputProps={{ style: {textAlign: 'center'} }}
                                        onChange={handleChangeCalifNote}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleSetNotes} style={{color: "#FFF"}}>Agregar</Button>
                            <Button onClick={handleCloseDialog}>Cancelar</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog onClose={handleCloseDialog} open={openDialogNotes2} maxWidth="xs" fullWidth>
                        <DialogContent>
                            <Grid container spacing={2} pt={5}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        variant="standard"
                                        label="Evaluación Interna"
                                        name="examen_final"
                                        value={valuesCalifNotes.examen_final}
                                        inputProps={{ style: {textAlign: 'center'} }}
                                        onChange={handleChangeCalifNote}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        variant="standard"
                                        label="Tarea Académica"
                                        name="examen_parcial"
                                        value={valuesCalifNotes.examen_parcial}
                                        inputProps={{ style: {textAlign: 'center'} }}
                                        onChange={handleChangeCalifNote}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        type='number'
                                        variant="standard"
                                        label="Avaluación de Resultados (Practicas UD)"
                                        name="trabajos"
                                        value={valuesCalifNotes.trabajos}
                                        inputProps={{ style: {textAlign: 'center'} }}
                                        onChange={handleChangeCalifNote}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleSetNotes} style={{color: "#FFF"}}>Agregar</Button>
                            <Button onClick={handleCloseDialog}>Cancelar</Button>
                        </DialogActions>
                    </Dialog>
                    </>
                )}
            </>
        ) : (
            <>
            No hay Cursos Inscritos
            </>
        )}
        </>
    );
}

export default RegisterNotes;