import { Divider } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllCourses } from "redux/actions/notesAction";
import RegisterCourse from "./sections/RegisterCourse";
import CourseList from "./sections/CourseList";
import { getAllScholls } from "redux/actions/notesAction";

const Courses = () => {
    const dispatch = useDispatch();
    const [actionCourse, setActionCourse] = useState("list");

    const handleChangeAction = (action) => {
        setActionCourse(action);
    }

    useEffect(async () => {
        await dispatch(getAllCourses());
        await dispatch(getAllScholls());
    });

    return(
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                GESTION DE CURSOS
            </MKTypography>
            <Divider />
            {actionCourse === "list" && (<CourseList onChangeAction={handleChangeAction} />)}
            {actionCourse === "register" && (<RegisterCourse onChangeAction={handleChangeAction} />)}
        </>
    )
}

export default Courses;