import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { set_one_school_action } from "redux/actions/notesAction";
import MKTypography from "components/MKTypography";
import ConfirmationDialog from "components/ConfirmationDialog";
import { getAllScholls } from "redux/actions/notesAction";
import { saveDeleteSchool } from "redux/actions/notesAction";
import { openNotifAction } from "redux/actions/severalAction";

const SchoolList = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerShool = "schoolReducer";
    const data_schools = useSelector(state => state[reducerShool].data_schools);
    const [dataSchools, setDataSchools] = useState(data_schools);
    const [deleteSchool, setDeleteSchool] = useState({
        id_escuela: 0
    });
    const [valorBusq, setValorBusq] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleFindSchool = (e) => {
        if(e.target.value === "") {
            setDataSchools(data_schools);
        } else {
            let newList = data_schools.filter(item => 
                item.escuela.toUpperCase().includes(e.target.value.toUpperCase()));
            setDataSchools(newList);
        }
        setValorBusq(e.target.value);
    }

    const handleSetAction = () => {
        dispatch(set_one_school_action(null));
        onChangeAction("register");
    }

    const handleEditSchool = async (school) => {
        await dispatch(set_one_school_action(school));
        onChangeAction("register");
    }

    const handleDeleteSchool = (school) => {
        setDeleteSchool({
            id_escuela: parseInt(school.id_escuela)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Escuela",
            content: "¿Seguro de Eliminar Escuela seleccionada?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataSchool = {
                "id_escuela": deleteSchool.id_escuela
            };
			const rptaSave = await saveDeleteSchool(newDataSchool);
            if(rptaSave.success) {
                dispatch(openNotifAction(["Eliminación exitosa!!!", "success", 'filled']));
                await dispatch(getAllScholls());
            } else {
                dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    const initData = () => {
        setDataSchools(data_schools);
    }

    useEffect(() => {
        initData();
    }, [data_schools]);


    return(
        <>
            {/* <MKTypography className='color-secondary' fontWeight='bold'>
                Listado
            </MKTypography>
            <Divider /> */}
            <Box>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            name="valorBusq"
                            value={valorBusq}
                            placeholder="Buscar..."
                            onChange={handleFindSchool}
                            color='text'
                            sx={{ padding: '5px'}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} textAlign='right'>
                        <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSetAction} >REGISTRAR NUEVO</Button>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow hover>
                        <TableCell align="left">Nombre</TableCell>
                        <TableCell align="right">...</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataSchools && dataSchools
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(school => {
                        return(
                            <TableRow key={"school" + school.id_escuela} hover>
                                <TableCell align="left">{school.escuela}</TableCell>
                                <TableCell align='right'>
                                    <Tooltip title='Editar'>
                                        <IconButton color='text' onClick={() => handleEditSchool(school)} ><EditIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                    <Tooltip title='Anular'>
                                        <IconButton color='text' onClick={() => handleDeleteSchool(school)}><DeleteIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                rowsPerPageOptions={[5,10,50,100]}
                page={page}
                rowsPerPage={rowsPerPage}
                count={dataSchools ? dataSchools.length : 0 }
                labelRowsPerPage=''
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationDialog 
                open={stateConfirmDialog.open}
                title={stateConfirmDialog.title}
                content={stateConfirmDialog.content}
                onClose={handleCloseConfirmDialog}
            />
        </>
    );
}

export default SchoolList;