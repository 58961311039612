import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const CicleSelect = (props) => {
    const { dataCicles, onSelectCicle, cicleSelected } = props;
    const [values, setValues] = useState({
        id_ciclo: 0
    });

    const handleChange = e => {
        let idCicle = parseInt(e.target.value);
        const oneCicle = dataCicles.filter(p=> p.id_ciclo === idCicle);
        setValues({ id_ciclo: idCicle });
        onSelectCicle(oneCicle[0]);
    }

    useEffect(() => {
        setValues({ id_ciclo: cicleSelected });
    }, [cicleSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Ciclo</InputLabel>
            <Select
                name="id_ciclo"
                value={values.id_ciclo}
                label="Ciclo"
                required
                onChange={handleChange}
            >
                {dataCicles && dataCicles.map(cicle => {
                    let keyCicle = "key" + cicle.id_ciclo;
                    return(
                        <MenuItem value={cicle.id_ciclo} key={keyCicle} >{cicle.ciclo}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default CicleSelect;