import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, TextField , Divider} from "@mui/material";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SexoSelect from "components/SeveralSelect/SexoSelect";
import { DATA_SEXO } from "helpers/helperInit";
import { dateFormatYYMMDD } from "helpers/helperInit";
import { saveNewTeacher } from "redux/actions/notesAction";
import { getAllTeachers } from "redux/actions/notesAction";
import { openNotifAction } from "redux/actions/severalAction";
import MKTypography from "components/MKTypography";

const RegisterTeacher = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerTeacher = "teacherReducer";
    const one_teacher = useSelector(state => state[reducerTeacher].one_teacher);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        id_profesor: 0,
        codprofesor: "",
        dni_persona: "",
        nom_persona: "",
        ape_paterno: "",
        ape_materno: "",
        foto_persona: "",
        email_persona: "",
        sexo: "1",
        f_nacimiento: "",
        especialidad: ""
    });

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleChangeSexo = (idSexo) => {
        setValues({ ...values, sexo: idSexo });
    }

    const handleChangeFecha = (value) => {
        const strDate = dateFormatYYMMDD(value);
        setValues({ ...values, f_nacimiento: strDate });
    }

    const handleSaveTeacher = async () => {
        if(values.codprofesor === "" || values.dni_persona === "" || values.ape_paterno === "" || values.ape_materno === "" || values.email_persona === "" || values.especialidad === "") {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        const rptaSave = await saveNewTeacher(values);
        if(parseInt(rptaSave.flagInsert) > 0 || parseInt(rptaSave.flagUpdate) > 0) {
            dispatch(openNotifAction([rptaSave.msg, "success", 'filled']));
            dispatch(getAllTeachers());
            onChangeAction("list");
        } else {
            dispatch(openNotifAction([rptaSave.msg, "error", 'filled']));
        }
    }

    const handleSetAction = () => {
        onChangeAction("list");
    }

    const initData = () => {
        if(one_teacher !== null) {
            setValues({
                id_profesor: one_teacher.id_profesor,
                codprofesor: one_teacher.codprofesor,
                dni_persona: one_teacher.dni_persona,
                nom_persona: one_teacher.nom_persona,
                ape_paterno: one_teacher.ape_paterno,
                ape_materno: one_teacher.ape_materno,
                foto_persona: one_teacher.foto_persona,
                email_persona: one_teacher.email_persona,
                sexo: one_teacher.sexo,
                f_nacimiento: one_teacher.f_nacimiento,
                especialidad: one_teacher.especialidad
            });
            setTitle("Actualizando registro");
        }
    }

    useEffect(() => {
        initData();
    }, [one_teacher]);

    return(
        <Box>
            <MKTypography className='color-secondary' fontWeight='bold'>
                {title}
            </MKTypography>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            variant="standard"
                            label="Fecha"
                            inputFormat="dd/MM/yyyy"
                            value={values.f_nacimiento}
                            color='text'
                            onChange={(newValue) => {
                                handleChangeFecha(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        variant="standard"
                        name="codprofesor"
                        value={values.codprofesor}
                        placeholder="Codigo"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        variant="standard"
                        name="dni_persona"
                        value={values.dni_persona}
                        placeholder="DNI"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        variant="standard"
                        name="nom_persona"
                        value={values.nom_persona}
                        placeholder="Nombre"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        variant="standard"
                        name="ape_paterno"
                        value={values.ape_paterno}
                        placeholder="Ape. Paterno"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        variant="standard"
                        name="ape_materno"
                        value={values.ape_materno}
                        placeholder="Ape. Materno"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <SexoSelect dataSexo={DATA_SEXO} onSelectSexo={handleChangeSexo} sexoSelected={values.sexo}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        variant="standard"
                        name="especialidad"
                        value={values.especialidad}
                        placeholder="Especialidad"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant="standard"
                        name="email_persona"
                        value={values.email_persona}
                        placeholder="E-mail"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} textAlign='right'>
                    <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSaveTeacher}>Guardar</Button>
                    <Button onClick={handleSetAction}>Cancelar</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default RegisterTeacher;