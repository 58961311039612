import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const CourseTypeSelect = (props) => {
    const { dataCourseTypes, onSelectCourseType, courseTypeSelected } = props;
    const [values, setValues] = useState({
        id_tipo: "1"
    });

    const handleChange = e => {
        let idType = parseInt(e.target.value);
        let oneCourseType = dataCourseTypes.filter(p=> p.id_tipo === idType);
        setValues({ id_tipo: idType });
        if(oneCourseType.length > 0) {
            onSelectCourseType(oneCourseType[0]);
        }
    }

    useEffect(() => {
        setValues({ id_tipo: courseTypeSelected });
    }, [courseTypeSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Tipo</InputLabel>
            <Select
                name="id_tipo"
                value={values.id_tipo}
                label="Tipo"
                required
                onChange={handleChange}
            >
                {/* <MenuItem value="-1" key="-1"></MenuItem> */}
                {dataCourseTypes && dataCourseTypes.map(courseType => {
                    let keyType = "key" + courseType.id_tipo;
                    return(
                        <MenuItem value={courseType.id_tipo} key={keyType} >{courseType.nombre}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default CourseTypeSelect;