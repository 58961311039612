import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ModuleSelect = (props) => {
    const { dataModules, onSelectModule, moduleSelected } = props;
    const [values, setValues] = useState({
        id_modulo: 0
    });

    const handleChange = e => {
        let idModule = parseInt(e.target.value);
        const oneModule = dataModules.filter(p=> p.id_modulo === idModule);
        setValues({ id_modulo: idModule });
        onSelectModule(oneModule[0]);
    }

    useEffect(() => {
        setValues({ id_modulo: moduleSelected });
    }, [moduleSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Modulo</InputLabel>
            <Select
                name="id_modulo"
                value={values.id_modulo}
                label="Modulo"
                required
                onChange={handleChange}
            >
                {dataModules && dataModules.map(module => {
                    let keyModule = "key" + module.id_modulo;
                    return(
                        <MenuItem value={module.id_modulo} key={keyModule} >{module.modulo}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default ModuleSelect;