import { Divider } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllTeachers } from "redux/actions/notesAction";
import RegisterTeacher from "./sections/RegisterTeacher";
import TeacherList from "./sections/TeacherList";

const Teachers = () => {
    const dispatch = useDispatch();
    const [actionTeacher, setActionTeacher] = useState("list");

    const handleChangeAction = (action) => {
        setActionTeacher(action);
    }

    useEffect(async () => {
        await dispatch(getAllTeachers());
    });

    return(
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                GESTION DE DOCENTES
            </MKTypography>
            <Divider />
            {actionTeacher === "list" && (<TeacherList onChangeAction={handleChangeAction} />)}
            {actionTeacher === "register" && (<RegisterTeacher onChangeAction={handleChangeAction} />)}
        </>
    )
}

export default Teachers;