import { ROOT_SERVER } from './helpers/helperInit'

// Pages
import DashboardAdmin from "layouts/public/dashboard-adm";

// import PublicationAdmin from "layouts/administrator/publications";

import SignIn from "layouts/public/authentication";
const routes = [
  {
    name: "pages",
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "Administrator",
            route: ROOT_SERVER + "/pages/dashboard/administrator",
            component: <DashboardAdmin />,
          },
        ],
      },
      // {
      //   name: "administrator",
      //   collapse: [
      //     {
      //       name: "publications",
      //       route: ROOT_SERVER + "/pages/administrator/publication",
      //       component: <PublicationAdmin />,
      //     }
      //   ],
      // },
      {
        name: "account",
        collapse: [
          {
            name: "sign in",
            route: ROOT_SERVER + "/pages/authentication/sign-in",
            component: <SignIn />,
          },
        ],
      },
    ],
  },
];

export default routes;
