import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, TextField, Divider } from "@mui/material";
import { openNotifAction } from "redux/actions/severalAction";
import MKTypography from "components/MKTypography";
import { saveNewPlan } from "redux/actions/notesAction";
import { getAllPlanes } from "redux/actions/notesAction";
import SchoolSelect from "../../Schools/sections/SchoolSelect";
import CoursesListCheck from "../../Courses/sections/CoursesListCheck";
import { saveNewPlanCurso } from "redux/actions/notesAction";
import { saveDeletePlanCurso } from "redux/actions/notesAction";
import { saveEditPlanCurso } from "redux/actions/notesAction";
import { getCoursesBySchool } from "redux/actions/notesAction";

const RegisterPlan = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerPlan = "planReducer";
    const reducerPlanCurso = "planCursoReducer";
    const reducerSchool = "schoolReducer";
    const one_plan = useSelector(state => state[reducerPlan].one_plan);
    const data_plan_cursos = useSelector(state => state[reducerPlanCurso].data_plan_cursos);
    const data_schools = useSelector(state => state[reducerSchool].data_schools);
    const [defaultSchool, setDefaultSchool] = useState(0);
    const [reloadCourses, setReloadCourses] = useState(false);
    const [coursesAssigned, setCoursesAssigned] = useState([]);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        id_plan: 0,
        plan: "",
        anio: new Date().getFullYear(),
        fk_escuela: 0,
    });

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleSavePlan = async () => {
        let flgCreditos = true;
        // console.log("coursesAssigned....", coursesAssigned);
        // return
        if(values.plan === "" || values.fk_escuela === 0) {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        for(var i = 0; i < coursesAssigned.length; i++) {
            if(coursesAssigned[i].creditos) {
                if(coursesAssigned[i].creditos <= 0 || coursesAssigned[i].fk_ciclo === 0 || coursesAssigned[i].fk_modulo === 0) {
                    flgCreditos = false;
                    break;    
                }
            } else {
                flgCreditos = false;
                break;
            }
        }
        if(!flgCreditos) {
            dispatch(openNotifAction(["Valor de creditos no válido, o no ha seleccionado ciclo", "error", 'filled']));
            return;
        }
        const rptaSave = await saveNewPlan(values);
        let fkPlan = values.id_plan;
        if(rptaSave.success) {
            if(fkPlan === 0) {
                fkPlan = rptaSave.data[0].id_plan;
            }
            for(var i= 0; i < data_plan_cursos.length; i++) {
                let oneCource = coursesAssigned.filter(l => parseInt(l.id_curso) === parseInt(data_plan_cursos[i].id_curso));
                if(oneCource.length === 0) {
                    const rptaSaveDelete = await saveDeletePlanCurso(data_plan_cursos[i]);
                }
            }
            for(var i = 0; i < coursesAssigned.length; i++) {
                coursesAssigned[i].fk_plan = fkPlan;
                coursesAssigned[i].fk_curso = coursesAssigned[i].id_curso;
                // coursesAssigned[i].tipo = "1";
                if(coursesAssigned[i].id_plan_curso) {
                    const rptaSavePlanCourse = await saveEditPlanCurso(coursesAssigned[i]);
                } else {
                    const rptaSavePlanCourse = await saveNewPlanCurso(coursesAssigned[i]);
                }
            }
            dispatch(openNotifAction(["Registro exitoso!!!", "success", 'filled']));
            // dispatch(getAllPlanes());
            // onChangeAction("list");
        } else {
            dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
        }
    }

    const handleAsignarCourses = async (courses) => {
        const newList = coursesAssigned;
        await courses.map(item => {
            // let newElement = item;
            item.fk_ciclo = 0;
            item.fk_modulo = 0;
            item.creditos = 0;
            newList.push(item);
        });
        await setCoursesAssigned(newList);
        setReloadCourses(!reloadCourses);
        // setCoursesAssigned(courses);
    }
    
    const handleDesasignarCourses = (courses) => {
        const newList = [];
        coursesAssigned.map(item => {
            let newCourse = courses.filter(l => parseInt(l.id_curso) === parseInt(item.id_curso));
            if(newCourse.length === 0) {
                newList.push(item);
            }
        });
        setCoursesAssigned(newList);
        setReloadCourses(!reloadCourses);
    }

    const handleSetCreditos = (course, valCred) => {
        if(valCred === "") valCred = 0;
        coursesAssigned.map(item => {
            if(parseInt(item.id_curso) === parseInt(course.id_curso)) {
                item.creditos = parseInt(valCred);
            }
        });
    }
    
    const handleSetModule = (course, fkModule) => {
        coursesAssigned.map(item => {
            if(parseInt(item.id_curso) === parseInt(course.id_curso)) {
                item.fk_modulo = parseInt(fkModule);
            }
        });
    }
    
    const handleSetCicle = (course, fkCicle) => {
        coursesAssigned.map(item => {
            if(parseInt(item.id_curso) === parseInt(course.id_curso)) {
                item.fk_ciclo = parseInt(fkCicle);
            }
        });
    }

    const handleSetAction = () => {
        onChangeAction("list");
    }

    const handleSelectSchool = (school) => {
        dispatch(getCoursesBySchool(school.id_escuela));
        setValues({...values, fk_escuela: school.id_escuela})
        if(one_plan === null) {
            setCoursesAssigned([]);
        }
    }

    const initData = async () => {
        if(one_plan !== null) {
            setValues({
                id_plan: one_plan.id_plan,
                plan: one_plan.plan,
                anio: one_plan.anio,
                fk_escuela: one_plan.fk_escuela
            });
            setDefaultSchool(one_plan.id_escuela);
            setCoursesAssigned(one_plan.curso);
            setTitle("Actualizando registro");
            await dispatch(getCoursesBySchool(one_plan.fk_escuela));
        }
    }

    useEffect(() => {
        initData();
    }, [one_plan]);

    return(
        <Box>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <MKTypography className='color-secondary' fontWeight='bold'>
                        {title}
                    </MKTypography>
                </Grid>
                <Grid item xs={12} lg={6} textAlign='right'>
                    <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSavePlan}>Guardar</Button>
                    <Button onClick={handleSetAction}>Cancelar</Button>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField 
                        variant="standard"
                        name="plan"
                        value={values.plan}
                        placeholder="Nombre del plan"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        variant="standard"
                        type="number"
                        name="anio"
                        value={values.anio}
                        placeholder="Año"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <SchoolSelect dataSchools={data_schools} onSelectSchool={handleSelectSchool} schoolSelected={defaultSchool} />
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <CoursesListCheck onActionList={handleAsignarCourses} actionList={"asignar"} dataAssigned={coursesAssigned} reload={reloadCourses} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <CoursesListCheck 
                        onActionList={handleDesasignarCourses} 
                        onSetCreditos={handleSetCreditos}
                        onSetModule={handleSetModule}
                        onSetCicle={handleSetCicle}
                        actionList={"desasignar"} 
                        dataAssigned={coursesAssigned} 
                        reload={reloadCourses} 
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default RegisterPlan;