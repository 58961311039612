import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const StudyPlanSelect = (props) => {
    const { dataStudyPlans, onSelectStudyPlan, studyPlanSelected } = props;
    const [values, setValues] = useState({
        id_plan: 0
    });

    const handleChange = e => {
        let idPlan = parseInt(e.target.value);
        const oneStudyPlan = dataStudyPlans.filter(p=> p.id_plan === idPlan);
        setValues({ id_plan: idPlan });
        onSelectStudyPlan(oneStudyPlan[0]);
    }

    useEffect(() => {
        setValues({ id_plan: studyPlanSelected });
    }, [studyPlanSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Plan de Estudios</InputLabel>
            <Select
                name="id_plan"
                value={values.id_plan}
                label="Plan de Estudios"
                required
                onChange={handleChange}
            >
                {dataStudyPlans && dataStudyPlans.map(studyPlan => {
                    let keyStudyPlan = "key" + studyPlan.id_plan;
                    return(
                        <MenuItem value={studyPlan.id_plan} key={keyStudyPlan} >{studyPlan.plan}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default StudyPlanSelect;