import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';

import MKTypography from "components/MKTypography";
import { Box, Card, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import { getCoursesInscribed } from "redux/actions/notesAction";
import { ID_CALI_FINAL } from "helpers/helperInit";
import { ID_CALI_PARTIAL } from "helpers/helperInit";
import { ID_CALI_WORK } from "helpers/helperInit";
import { ID_CALI_PRACTICE } from "helpers/helperInit";
import { ID_INSTITUCION } from "helpers/helperInit";
import MatriculaSelect from "components/SeveralSelect/MatriculaSelect";

const NotesStudent = () => {
    const reducerMatricula = "matriculaReducer";
    const data_matriculas = useSelector(state => state[reducerMatricula].data_matriculas);
    const [oneMatricula, setOneMatricula] = useState(null);
    const [loadPage, setLoadPage] = useState(false);
    const [dataOriginClassroom, setDataOriginClassroom] = useState(null);
    const [dataClassInscribed, setDataClassInscribed] = useState(null);
    const [valuesMatricula, setValuesMatricula] = useState([]);
    const [defaulIdMatricula, setDefaultIdMatricula] = useState(0);
    const [labelNota, setLabelNota] = useState({
        nota1: ID_INSTITUCION === 1 ? "Examen Parcial" : "Evaluación Interna",
        nota2: ID_INSTITUCION === 1 ? "Examen Final" : "Tarea Académica",
        nota3: ID_INSTITUCION === 1 ? "Participación diaria(orales, tareas académicas, asistencia)" : "Avaluación de Resultados (Practicas UD)",
        nota4: ID_INSTITUCION === 1 ? "Presentación y exposición de trabajo final" : ""
    });
    const hasConn = sessionStorage.getItem('hasConn');
    const jsonHasConn = JSON.parse(hasConn);
    const idStudent = jsonHasConn.idAlumno;
    let exaFinal = 0;
    let exaParcial = 0;
    let trabajos = 0;
    let participacion = 0;

    const GetDataMatricula = async (idMatricula) => {
        const rptaReturn = await getCoursesInscribed(idStudent);
        console.log("rptaReturn....:", rptaReturn)
        const rptaData = rptaReturn.data;
        const newList = rptaData.filter(item => parseInt(item.id_matricula) === parseInt(idMatricula));
        // await setOneMatricula(matrOpen[0]);
        setDataClassInscribed(newList);
        setDataOriginClassroom(newList);
    }

    const handleChangeMatricula = (matricula) => {
        setDefaultIdMatricula(matricula.id_matricula);
        GetDataMatricula(matricula.id_matricula);
    }

    const initData = async () => {
        let listMatricula = [];
        if(data_matriculas !== null) {
            if(data_matriculas.length > 0) {
                const matrOpen = data_matriculas.filter(item => item.estadoMatricula === 1);
                if(matrOpen.length > 0) {
                    const rptaReturn = await getCoursesInscribed(idStudent);
                    const rptaData = rptaReturn.data;
                    const newList = rptaData.filter(item => parseInt(item.id_matricula) === parseInt(matrOpen[0].id_matricula));
                    await setOneMatricula(matrOpen[0]);
                    setDataClassInscribed(newList);
                    setDataOriginClassroom(newList);
                    setDefaultIdMatricula(matrOpen[0].id_matricula);
                }
                data_matriculas.map(item => {
                    let oneMatr = {
                        id_matricula: item.id_matricula,
                        anio: item.anio,
                        periodo: item.periodo,
                        descripcion: item.descripcion
                    }
                    listMatricula.push(oneMatr);
                })
            }
        }
        setValuesMatricula(listMatricula);
        setLoadPage(true);
    }

    useEffect(() => {
        initData();
    }, [loadPage, data_matriculas]);

    return(
        <>
        {!!loadPage ? (
            <>
                {oneMatricula !== null && (
                    <>
                    <Box>
                        <MatriculaSelect dataMatriculas={valuesMatricula} onSelectMatricula={handleChangeMatricula} matriculaSelected={defaulIdMatricula} />
                    </Box>
                    {/* <MKTypography display='inline' fontWeight='bold' sx={{fontSize: '15px'}}>
                        Matrícula: {oneMatricula.anio.toString() + " - " + oneMatricula.periodo}
                    </MKTypography> */}

                    <Divider />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card sx={{border: '1px solid #CCC'}}>
                                <Table>
                                    <TableBody>
                                        <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                            <TableCell align="left">Curso</TableCell>
                                            <TableCell align="center">Profesor</TableCell>
                                            <TableCell align="center">{labelNota.nota1}</TableCell>
                                            <TableCell align="center">{labelNota.nota2}</TableCell>
                                            <TableCell align="center">{labelNota.nota3}</TableCell>
                                            {ID_INSTITUCION === 1 && (
                                                <TableCell align="center">{labelNota.nota4}</TableCell>
                                            )}
                                            <TableCell align="center">Nota</TableCell>
                                        </TableRow>
                                        {dataClassInscribed !== null && dataClassInscribed.map((course, index) => {
                                            exaFinal = 0;
                                            exaParcial = 0;
                                            trabajos = 0;
                                            participacion = 0;
                                            let oneNote = [];
                                            if(course.notas !== null) {
                                                oneNote = course.notas.filter(item => parseInt(item.fk_tipocalificacion) === parseInt(ID_CALI_FINAL));
                                                if(oneNote.length > 0) {
                                                    exaFinal = oneNote[0].nota;
                                                }
                                                oneNote = course.notas.filter(item => parseInt(item.fk_tipocalificacion) === parseInt(ID_CALI_PARTIAL));
                                                if(oneNote.length > 0) {
                                                    exaParcial = oneNote[0].nota;
                                                }
                                                oneNote = course.notas.filter(item => parseInt(item.fk_tipocalificacion) === parseInt(ID_CALI_WORK));
                                                if(oneNote.length > 0) {
                                                    trabajos = oneNote[0].nota;
                                                }
                                                oneNote = course.notas.filter(item => parseInt(item.fk_tipocalificacion) === parseInt(ID_CALI_PRACTICE));
                                                if(oneNote.length > 0) {
                                                    participacion = oneNote[0].nota;
                                                }
                                            }
                                            return(
                                                <TableRow 
                                                    key={"classroom" + course.id_alumno_aula} 
                                                    hover 
                                                >
                                                    <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                        {index + 1 + ") " + course.curso[0].curso}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                        {course.profesor[0].nom_persona + " " + course.profesor[0].ape_paterno + " " + course.profesor[0].ape_materno}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                        {exaFinal}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                        {exaParcial}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                        {trabajos}
                                                    </TableCell>
                                                    {ID_INSTITUCION === 1 && (
                                                        <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                            {participacion}
                                                        </TableCell>
                                                    )}
                                                    <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '12px'}}>
                                                        {course.nota}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                    </Grid>
          
                    </>
                )}
            </>
        ) : (
            <>
            No hay Cursos Inscritos
            </>
        )}
        </>
    );
}

export default NotesStudent;