import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MKTypography from "components/MKTypography";
import ConfirmationDialog from "components/ConfirmationDialog";
import { openNotifAction } from "redux/actions/severalAction";
import { saveDeletePlan } from "redux/actions/notesAction";
import { set_one_plan_action } from "redux/actions/notesAction";
import { getAllPlanes } from "redux/actions/notesAction";
import { getAllPlanCursos } from "redux/actions/notesAction";
import { getAllModules } from "redux/actions/notesAction";
import { getAllCicles } from "redux/actions/notesAction";
import { get_all_courses_action } from "redux/actions/notesAction";

const PlanList = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerPlan = "planReducer";
    const data_planes = useSelector(state => state[reducerPlan].data_planes);
    const [dataPlanes, setDataPlanes] = useState(data_planes);
    const [deletePlan, setDeletePlan] = useState({
        id_plan: 0
    });
    const [valorBusq, setValorBusq] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleFindPlan = (e) => {
        if(e.target.value === "") {
            setDataPlanes(data_planes);
        } else {
            let newList = data_planes.filter(item => 
                item.plan.toUpperCase().includes(e.target.value.toUpperCase()));
            setDataPlanes(newList);
        }
        setValorBusq(e.target.value);
    }

    const handleSetAction = () => {
        dispatch(set_one_plan_action(null));
        onChangeAction("register");
    }

    const handleEditPlan = async (plan) => {
        // await dispatch(getAllPlanCursos(plan));
        await dispatch(set_one_plan_action(plan));
        onChangeAction("register");
    }

    const handleDeletePlan = (plan) => {
        setDeletePlan({
            id_plan: parseInt(plan.id_plan)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Plan",
            content: "¿Seguro de Eliminar Plan seleccionado?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataPlan = {
                "id_plan": deletePlan.id_plan
            };
			const rptaSave = await saveDeletePlan(newDataPlan);
            if(rptaSave.success) {
                dispatch(openNotifAction(["Eliminación exitosa!!!", "success", 'filled']));
                await dispatch(getAllPlanes());
            } else {
                dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    const initData = () => {
        setDataPlanes(data_planes);
        dispatch(getAllModules());
        dispatch(getAllCicles());
        dispatch(get_all_courses_action([]));
    }

    useEffect(() => {
        initData();
    }, [data_planes]);


    return(
        <>
            <Box>
                {/* <MKTypography className='color-secondary' fontWeight='bold'>
                    Listado
                </MKTypography> */}
                <Divider />
                <Grid container>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            name="valorBusq"
                            value={valorBusq}
                            placeholder="Buscar..."
                            onChange={handleFindPlan}
                            color='text'
                            sx={{ padding: '5px'}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} textAlign='right'>
                        <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSetAction} >REGISTRAR NUEVO</Button>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow hover>
                        <TableCell align="left">Plan</TableCell>
                        <TableCell align="left">Año</TableCell>
                        <TableCell align="left">Escuela</TableCell>
                        <TableCell align="right">...</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataPlanes && dataPlanes
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(plan => {
                        return(
                            <TableRow key={"plan" + plan.id_plan} hover>
                                <TableCell align="left">{plan.plan}</TableCell>
                                <TableCell align="left">{plan.anio}</TableCell>
                                <TableCell align="left">{plan.escuela}</TableCell>
                                <TableCell align='right'>
                                    <Tooltip title='Editar'>
                                        <IconButton color='text' onClick={() => handleEditPlan(plan)} ><EditIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                    <Tooltip title='Anular'>
                                        <IconButton color='text' onClick={() => handleDeletePlan(plan)}><DeleteIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                rowsPerPageOptions={[5,10,50,100]}
                page={page}
                rowsPerPage={rowsPerPage}
                count={dataPlanes ? dataPlanes.length : 0 }
                labelRowsPerPage=''
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationDialog 
                open={stateConfirmDialog.open}
                title={stateConfirmDialog.title}
                content={stateConfirmDialog.content}
                onClose={handleCloseConfirmDialog}
            />
        </>
    );
}

export default PlanList;