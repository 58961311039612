import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SchoolSelect = (props) => {
    const { dataSchools, onSelectSchool, schoolSelected } = props;
    const [values, setValues] = useState({
        id_escuela: 0
    });

    const handleChange = e => {
        let idSchool = parseInt(e.target.value);
        const oneSchool = dataSchools.filter(p=> p.id_escuela === idSchool);
        setValues({ id_escuela: idSchool });
        onSelectSchool(oneSchool[0]);
    }

    useEffect(() => {
        setValues({ id_escuela: schoolSelected });
    }, [schoolSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Escuela</InputLabel>
            <Select
                name="id_escuela"
                value={values.id_escuela}
                label="Escuela"
                required
                onChange={handleChange}
            >
                {dataSchools && dataSchools.map(school => {
                    let keySchool = "key" + school.id_escuela;
                    return(
                        <MenuItem value={school.id_escuela} key={keySchool} >{school.escuela}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SchoolSelect;