import { typesCourses } from "redux/constants/type";
import { typesPlan } from "redux/constants/type";
import { typesMatricula } from "redux/constants/type";
import { typesModules } from "redux/constants/type";
import { typesCalificationType } from "redux/constants/type";
import { typesCicles } from "redux/constants/type";
import { typesClassroom } from "redux/constants/type";
import { typesPlanCurso } from "redux/constants/type";
import { typesSchools } from "redux/constants/type";
import { typesTeachers } from "redux/constants/type";
import { typesStudents } from "redux/constants/type";

const initialStateStudent = {
    data_students: [],
    one_student: null
}

const initialStateTeacher = {
    data_teachers: [],
    one_teacher: null
}

const initialStateCourse = {
    data_courses: [],
    one_course: null
}

const initialStateSchool = {
    data_schools: [],
    one_school: null
}

const initialStatePlan = {
    data_planes: [],
    one_plan: null
}

const initialStatePlanCurso = {
    data_plan_cursos: [],
    one_plan_curso: null
}

const initialStateMatricula = {
    data_matriculas: [],
    one_matricula: null
}

const initialStateClassroom = {
    data_classrooms: [],
    one_classroom: null
}

const initialStateModule = {
    data_modules: [],
    one_module: null
}

const initialStateCicle = {
    data_cicles: [],
    one_cicle: null
}

const initialStateCalificationType = {
    data_calification_types: [],
}

export const studentReducer = (state = initialStateStudent, action) => {
    switch (action.type) {
        case typesStudents.read:
            return {
                ...state,
                data_students: action.payload
            }
        case typesStudents.setOneStudent:
            return {
                ...state,
                one_student: action.payload,
            }
        default:
            return state;
    }
}

export const teacherReducer = (state = initialStateTeacher, action) => {
    switch (action.type) {
        case typesTeachers.read:
            return {
                ...state,
                data_teachers: action.payload
            }
        case typesTeachers.setOneTeacher:
            return {
                ...state,
                one_teacher: action.payload,
            }
        default:
            return state;
    }
}

export const courseReducer = (state = initialStateCourse, action) => {
    switch (action.type) {
        case typesCourses.read:
            return {
                ...state,
                data_courses: action.payload
            }
        case typesCourses.setOneCourse:
            return {
                ...state,
                one_course: action.payload,
            }
        default:
            return state;
    }
}

export const schoolReducer = (state = initialStateSchool, action) => {
    switch (action.type) {
        case typesSchools.read:
            return {
                ...state,
                data_schools: action.payload
            }
        case typesSchools.setOneSchool:
            return {
                ...state,
                one_school: action.payload,
            }
        default:
            return state;
    }
}

export const planReducer = (state = initialStatePlan, action) => {
    switch (action.type) {
        case typesPlan.read:
            return {
                ...state,
                data_planes: action.payload
            }
        case typesPlan.setOnePlan:
            return {
                ...state,
                one_plan: action.payload,
            }
        default:
            return state;
    }
}

export const planCursoReducer = (state = initialStatePlanCurso, action) => {
    switch (action.type) {
        case typesPlanCurso.read:
            return {
                ...state,
                data_plan_cursos: action.payload
            }
        // case typesPlan.setOnePlan:
        //     return {
        //         ...state,
        //         one_plan: action.payload,
        //     }
        default:
            return state;
    }
}


export const matriculaReducer = (state = initialStateMatricula, action) => {
    switch (action.type) {
        case typesMatricula.read:
            return {
                ...state,
                data_matriculas: action.payload
            }
        case typesMatricula.setOneMatricula:
            return {
                ...state,
                one_matricula: action.payload,
            }
        default:
            return state;
    }
}

export const classroomReducer = (state = initialStateClassroom, action) => {
    switch (action.type) {
        case typesClassroom.read:
            return {
                ...state,
                data_classrooms: action.payload
            }
        case typesClassroom.setOneClassroom:
            return {
                ...state,
                one_classroom: action.payload,
            }
        default:
            return state;
    }
}

export const moduleReducer = (state = initialStateModule, action) => {
    switch (action.type) {
        case typesModules.read:
            return {
                ...state,
                data_modules: action.payload
            }
        default:
            return state;
    }
}

export const cicleReducer = (state = initialStateCicle, action) => {
    switch (action.type) {
        case typesCicles.read:
            return {
                ...state,
                data_cicles: action.payload
            }
        default:
            return state;
    }
}

export const calificationTypeReducer = (state = initialStateCalificationType, action) => {
    switch (action.type) {
        case typesCalificationType.read:
            return {
                ...state,
                data_calification_types: action.payload
            }
        default:
            return state;
    }
}