import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import MKTypography from "components/MKTypography";
import { Box, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import SchoolSelect from "../../Schools/sections/SchoolSelect";
import { openNotifAction } from "redux/actions/severalAction";
import { getCoursesAvailable } from "redux/actions/notesAction";
import { saveStudentClassroom } from "redux/actions/notesAction";
import { getCoursesInscribed } from "redux/actions/notesAction";
import ConfirmationDialog from "components/ConfirmationDialog";
import { saveDeleteStudentClassroom } from "redux/actions/notesAction";
import CicleSelect from "../../Module/Sections/CicleSelect";

const RegisterInscription = () => {
    const dispatch = useDispatch();
    const reducerSchool = "schoolReducer";
    const reducerClassroom = "classroomReducer";
    const reducerMatricula = "matriculaReducer";
    const reducerStudent = "studentReducer";
    const reducerCicle = "cicleReducer";
    const data_matriculas = useSelector(state => state[reducerMatricula].data_matriculas);
    const data_schools = useSelector(state => state[reducerSchool].data_schools);
    const data_classrooms = useSelector(state => state[reducerClassroom].data_classrooms);
    const data_students = useSelector(state => state[reducerStudent].data_students);
    const data_cicles = useSelector(state => state[reducerCicle].data_cicles);
    const [oneMatricula, setOneMatricula] = useState(null);
    const [loadPage, setLoadPage] = useState(false);
    const [dataOriginClassroom, setDataOriginClassroom] = useState(null);
    const [dataClassroom, setDataClassroom] = useState(null);
    const [dataStudents, setDataStudents] = useState(null);
    const [dataClassInscribed, setDataClassInscribed] = useState(null);
    const [oneClassroom, setOneClassroom] = useState(null);
    const [oneStudent, setOneStudent] = useState(null);
    const [reload, setReload] = useState(false);
    const [expanStudent, setExpanStudent] = useState(false);
    const [expanClassroom, setExpanClassroom] = useState(true);
    const [valorBusqStudent, setValorBusqStudent] = useState("");
    const [valorBusqClassroom, setValorBusqClassroom] = useState("");
    const [defaultCicle, setDefaultCicle] = useState(0);
    const [deleteClassInscribed, setDeleteClassInscribed] = useState({
        id_alumno_aula: 0
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    let nomProf = "";
    let nomAlum = "";

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleExpandStudent = () => {
        setExpanStudent(!expanStudent);
    };
    
    const handleExpandClassroom = () => {
        // setExpanClassroom(!expanClassroom);
    };

    const handleSelectClassroom = (classroom) => {
        setOneClassroom(classroom);
        // setExpanClassroom(!expanClassroom);
    }

    const loadDataTables = async (idEstudent) => {
        let rptaGetCourses = await getCoursesAvailable(idEstudent);
        const courses = rptaGetCourses.data;
        let newList = [];
        courses.map(item => {
            const oneClassroom = data_classrooms.filter(classroom => classroom.id_aula === item.id_aula);
            newList.push(oneClassroom[0]);
        });
        setDataClassroom(newList);        
        setDataOriginClassroom(newList);
        rptaGetCourses = await getCoursesInscribed(idEstudent);
        setDataClassInscribed(rptaGetCourses.data)
    }

    const handleSelectStudent = async (student) => {
        // let rptaGetCourses = await getCoursesAvailable(student.id_alumno);
        // const courses = rptaGetCourses.data;
        // let newList = [];
        // courses.map(item => {
        //     const oneClassroom = data_classrooms.filter(classroom => classroom.id_aula === item.id_aula);
        //     newList.push(oneClassroom[0]);
        // });
        // setDataClassroom(newList);        
        // setDataOriginClassroom(newList);
        // rptaGetCourses = await getCoursesInscribed(student.id_alumno);
        // setDataClassInscribed(rptaGetCourses.data)
        await loadDataTables(student.id_alumno);
        await setOneStudent(student);
        await setExpanStudent(!expanStudent);
    }

    const handleSaveStudentClassroom = async () => {
        const newValues = {
            id_alumno_aula: 0,
            id_aula: oneClassroom.id_aula,
            id_alumno: oneStudent.id_alumno
        };
        const rptaSave = await saveStudentClassroom(newValues);
        if(rptaSave.success) {
            dispatch(openNotifAction([rptaSave.data[0].msg, "success", 'filled']));
            await loadDataTables(oneStudent.id_alumno);
            setReload(!reload);
        } else {
            dispatch(openNotifAction([rptaSave.data[0].msg, "error", 'filled']));
        }
    }

    const handleDeleteClassInscribed = (classroom) => {
        setDeleteClassInscribed({
            id_alumno_aula: parseInt(classroom.id_alumno_aula)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Inscrición",
            content: "¿Seguro de Eliminar el registro seleccionado?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataClassroom = {
                "id_alumno_aula": deleteClassInscribed.id_alumno_aula
                // "id_alumno_aula": 8
            };
			const rptaSave = await saveDeleteStudentClassroom(newDataClassroom);
            if(rptaSave.success) {
                dispatch(openNotifAction(["Eliminación exitosa!!!", "success", 'filled']));
                // await dispatch(getAllClassrooms());
                // dispatch(set_one_classroom_action(null));
                await loadDataTables(oneStudent.id_alumno);
                setReload(!reload);
            } else {
                dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    const handleFindStudent = (e) => {
        if(e.target.value === "") {
            setDataStudents(data_students);
        } else {
            let newList = data_students.filter(item => 
                item.dni_persona.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.nom_persona.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.ape_paterno.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.ape_materno.toUpperCase().includes(e.target.value.toUpperCase())
            );
            setDataStudents(newList);
        }
        setValorBusqStudent(e.target.value);
    }
    
    const handleFindClassroom = (e) => {
        if(e.target.value === "") {
            setDataClassroom(dataOriginClassroom);
        } else {
            let newList = dataOriginClassroom.filter(item => 
                item.curso[0].curso.toUpperCase().includes(e.target.value.toUpperCase()) 
            );
            setDataClassroom(newList);
        }
        setValorBusqClassroom(e.target.value);
    }

    const handleChangeCicle = async (cicle) => {
        setDefaultCicle(cicle.id_ciclo);
        let newList = dataOriginClassroom;
        if(valorBusqClassroom !== "") {
            newList = await dataOriginClassroom.filter(item => item.curso[0].curso.toUpperCase().includes(valorBusqClassroom.toUpperCase()) );
        }
        newList = await newList.filter(item => parseInt(item.id_ciclo) === parseInt(cicle.id_ciclo));        
        setDataClassroom(newList);
    }

    const initData = async () => {
        if(data_matriculas !== null) {
            if(data_matriculas.length > 0) {
                const matrOpen = data_matriculas.filter(item => item.estadoMatricula === 1);
                if(matrOpen.length > 0) {
                    const newList = data_classrooms.filter(item => parseInt(item.id_matricula) === parseInt(matrOpen[0].id_matricula));
                    await setOneMatricula(matrOpen[0]);
                }
            }
        }
        if(data_students !== null) {
            setDataStudents(data_students);
        }
        setLoadPage(true);
    }

    useEffect(() => {
        initData();
    }, [loadPage, data_matriculas, reload]);

    return(
        <>
        {!!loadPage ? (
            <>
                {oneMatricula !== null && (
                    <>
                    <MKTypography display='inline' fontWeight='bold' sx={{fontSize: '15px'}}>
                        Matrícula: {oneMatricula.anio.toString() + " - " + oneMatricula.periodo}
                    </MKTypography>
                    {/* <Divider /> */}


                    <Accordion expanded={expanStudent} onChange={handleExpandStudent}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            {oneStudent !== null ? (
                                <MKTypography display='inline' sx={{fontSize: '15px'}}>
                                    <b>Alumno:</b> {oneStudent !== null ? oneStudent.nom_persona + " " + oneStudent.ape_paterno + " " + oneStudent.ape_materno + " / " + oneStudent.escuela : ""}
                                </MKTypography>
                            ) : (
                                <MKTypography display='inline' sx={{fontSize: '15px', fontStyle: 'italic', color: '#CCC'}}>
                                    Seleccione alumno
                                </MKTypography>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField 
                                        variant="standard"
                                        name="valorBusqStudent"
                                        value={valorBusqStudent}
                                        placeholder="Buscar..."
                                        onChange={handleFindStudent}
                                        color='text'
                                        sx={{ padding: '5px'}}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Table>
                                <TableBody>
                                    <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                        <TableCell align="left">Codigo</TableCell>
                                        <TableCell align="left">Dni</TableCell>
                                        <TableCell align="left">Nombre</TableCell>
                                        <TableCell align="left">Escuela</TableCell>
                                    </TableRow>
                                    {dataStudents !== null && dataStudents
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(student => {
                                        nomAlum = student.nom_persona + " " + student.ape_paterno + " " + student.ape_materno;
                                        return(
                                            <TableRow 
                                                key={"student" + student.id_alumno} 
                                                hover 
                                                sx={{cursor: 'pointer'}} 
                                                onClick={() => handleSelectStudent(student)}
                                            >
                                                <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{student.codigo}</TableCell>
                                                <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{student.dni_persona}</TableCell>
                                                <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{nomAlum}</TableCell>
                                                <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{student.escuela}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        <TablePagination
                            component='div'
                            rowsPerPageOptions={[5,10,50,100]}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            count={dataStudents ? dataStudents.length : 0 }
                            labelRowsPerPage=''
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        </AccordionDetails>
                    </Accordion>


                    <Divider />
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Accordion expanded={expanClassroom} onChange={handleExpandClassroom} sx={{border: '1px solid #CCC'}}>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Grid container>
                                        <Grid item xs={12} lg={10}>
                                            {oneClassroom !== null ? (
                                                <MKTypography display='inline' sx={{fontSize: '13px'}}>
                                                    <b>Aula:</b> {oneClassroom !== null ? oneClassroom.curso[0].curso + " / " + oneClassroom.profesor[0].nom_persona + " " + oneClassroom.profesor[0].ape_paterno : ""}
                                                </MKTypography>
                                            ) : (
                                                <MKTypography display='inline' sx={{fontSize: '15px', fontStyle: 'italic', color: '#CCC'}}>
                                                    Seleccione aula
                                                </MKTypography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            {/* <Box textAlign={'right'}> */}
                                                <Button variant="contained" className="bg-secondary" style={{color: '#FFF'}} onClick={handleSaveStudentClassroom}>Inscribir</Button>
                                            {/* </Box> */}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12} lg={8}>
                                            <TextField 
                                                variant="standard"
                                                name="valorBusqClassroom"
                                                value={valorBusqClassroom}
                                                placeholder="Buscar curso..."
                                                onChange={handleFindClassroom}
                                                color='text'
                                                sx={{ padding: '5px'}}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <CicleSelect dataCicles={data_cicles} onSelectCicle={handleChangeCicle} cicleSelected={defaultCicle} />
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableBody>
                                            <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                                {/* <TableCell align="left">Escuela</TableCell> */}
                                                <TableCell align="left">Curso</TableCell>
                                                <TableCell align="left">Profesor</TableCell>
                                                {/* <TableCell align="left">C.Min</TableCell> */}
                                                <TableCell align="left">Ciclo</TableCell>
                                            </TableRow>
                                            {dataClassroom !== null && dataClassroom
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(classroom => {
                                                nomProf = classroom.profesor[0].nom_persona + " " + classroom.profesor[0].ape_paterno;
                                                return(
                                                    <TableRow 
                                                        key={"classroom" + classroom.id_aula} 
                                                        hover 
                                                        sx={{cursor: 'pointer'}} 
                                                        onClick={() => handleSelectClassroom(classroom)}
                                                    >
                                                        {/* <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.escuela[0].escuela}</TableCell> */}
                                                        <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>{classroom.curso[0].curso}</TableCell>
                                                        <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>{nomProf}</TableCell>
                                                        {/* <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.capacidadmin}</TableCell> */}
                                                        <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '13px'}}>{classroom.ciclo}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[5,10,50,100]}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    count={dataClassroom ? dataClassroom.length : 0 }
                                    labelRowsPerPage=''
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Accordion expanded={expanClassroom} onChange={handleExpandClassroom} sx={{border: '1px solid #CCC'}}>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <MKTypography display='inline' sx={{fontSize: '13px'}}>
                                                CURSOS INSCRITOS
                                            </MKTypography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <Grid container>
                                        <TextField 
                                            variant="standard"
                                            name="valorBusqClassroom"
                                            value={valorBusqClassroom}
                                            placeholder="Buscar curso..."
                                            onChange={handleFindClassroom}
                                            color='text'
                                            sx={{ padding: '5px'}}
                                            fullWidth
                                        />
                                    </Grid> */}
                                    <Table>
                                        <TableBody>
                                            <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                                {/* <TableCell align="left">Escuela</TableCell> */}
                                                <TableCell align="left">Curso</TableCell>
                                                <TableCell align="left">Profesor</TableCell>
                                                {/* <TableCell align="left">C.Min</TableCell> */}
                                                {/* <TableCell align="left">C.Max</TableCell> */}
                                            </TableRow>
                                            {dataClassInscribed !== null && dataClassInscribed
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(classroom => {
                                                nomProf = classroom.profesor[0].nom_persona + " " + classroom.profesor[0].ape_paterno;
                                                return(
                                                    <TableRow 
                                                        key={"classroom" + classroom.fk_aula} 
                                                        hover 
                                                        sx={{cursor: 'pointer'}} 
                                                        onClick={() => handleSelectClassroom(classroom)}
                                                    >
                                                        {/* <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.escuela[0].escuela}</TableCell> */}
                                                        <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>{classroom.curso[0].curso}</TableCell>
                                                        <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '12px'}}>{nomProf}</TableCell>
                                                        {/* <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.capacidadmin}</TableCell> */}
                                                        {/* <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.capacidadmax}</TableCell> */}
                                                        <TableCell align='right'>
                                                            <Tooltip title='Anular'>
                                                                <IconButton color='text' onClick={() => handleDeleteClassInscribed(classroom)}><DeleteIcon fontSize='small' /></IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                <TablePagination
                                    component='div'
                                    rowsPerPageOptions={[5,10,50,100]}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    count={dataClassInscribed ? dataClassInscribed.length : 0 }
                                    labelRowsPerPage=''
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
          
                    <ConfirmationDialog 
                        open={stateConfirmDialog.open}
                        title={stateConfirmDialog.title}
                        content={stateConfirmDialog.content}
                        onClose={handleCloseConfirmDialog}
                    />
                    {/* <Divider /> */}
                    </>
                )}
            </>
        ) : (
            <>
            No hay matricula aperturada
            </>
        )}
        </>
    );
}

export default RegisterInscription;