export const typesNotif = {
    OPEN_NOTIF: "[Notif] open_notyf",
    CLOSE_NOTIF: "[Notif] close_notif",
};

export const typesSeveral = {
    openFrmGalery: "[Several] openFrmGalery",
    openLoader: "[Several] openLoader",
};

export const typesSystemParamts = {
    read: "[SystemParamts] read",
};

export const typesMenu = {
    readKeysPublic: "[Menu] readKeysPublic",
    readUserPermits: "[Menu] readUserPermits",
}

export const typesUser = {
    readUsers: "[User] readUser",
    openFrm: "[User] openFrm",
    openChangePass: "[User] openChangePass",
    search: "[User] search",
    openFrmPermisions: "[User] openFrmPermissions",
};

export const typesGeneralData = {
    read: "[GeneralData] read",
}

export const typesStudents = {
    read: "[Students] read",
    setOneStudent: "[Student] setOneStudent"
}

export const typesTeachers = {
    read: "[Teachers] read",
    setOneTeacher: "[Teachers] setOneTeacher",
}

export const typesCourses = {
    read: "[Courses] read",
    setOneCourse: "[Courses] setOneCourse",
}

export const typesSchools = {
    read: "[Schools] read",
    setOneSchool: "[Courses] setOneSchool",
}

export const typesPlan = {
    read: "[Plan] read",
    setOnePlan: "[Plan] setOnePlan",
}

export const typesPlanCurso = {
    read: "[PlanCurso] read",
    setOnePlanCurso: "[PlanCurso] setOnePlanCurso",
}

export const typesMatricula = {
    read: "[Matricula] read",
    setOneMatricula: "[Matricula] setOneMatricula",
}

export const typesClassroom = {
    read: "[Classroom] read",
    setOneClassroom: "[Classroom] setOneClassroom",
}

export const typesModules = {
    read: "[Module] read",
    setOneModule: "[Module] setOneModule",
}

export const typesCicles = {
    read: "[Cicle] read",
    setOneCicle: "[Cicle] setOneCicle",
}

export const typesCalificationType = {
    read: "[CalificationType] read",
}