const URL_SERVICE = process.env.URL_SERVICE || "https://iestpmorropon.edu.pe:10060/";
// const URL_SERVICE = process.env.URL_SERVICE || "http://solusofthencarpa.net.pe/RestApiNotas/";

const ROOT_SERVER = process.env.ROOT_SERVER || ""; // "/react-gestor"
// const ROOT_SERVER = process.env.ROOT_SERVER || "/notasHuaca"; // "/react-gestor"
// "homepage": "http://solusofthencarpa.net.pe/notasHuaca/",

const ROOT_GALERIA = ROOT_SERVER + '/static/images/galeria/';
const ROOT_FILES_PDF = ROOT_SERVER + '/static/files/pdf/';
const MESSAGE_NOT_CONNECT_SERVER = "No es posible conectar con el Servidor";
const INSTITUTION_SUBTITLE = "Instituto de Educación Superior Tecnológico Público";
const ID_INSTITUCION = 2; // 1 = LA HUACA; 2 = AYABACA
const NOM_CORTO_INSTITUCION1 = "ISTP LA HUACA";
const NOM_CORTO_INSTITUCION2 = "ISTP AYABACA";
const LOGO_INSTITUCION1 = "logo1.png";
const LOGO_INSTITUCION2 = "logo.png";

const MENU_INIT = [
    // {
    //     "id_modulo": 1,
    //     "name": "Nosotros",
    //     "columns": "1",
    //     "rowsPerColumn": 1,
    //     "key_menu": "mod-us",
    //     "route": "/pages/about-us",
    //     "icon": "",
    //     "collapse": null
    // },
    // {
    //     "id_modulo": 2,
    //     "name": "Escuelas",
    //     "columns": "1",
    //     "rowsPerColumn": 1,
    //     "key_menu": "mod-schools",
    //     "route": "/pages/programas",
    //     "icon": "",
    //     "collapse": null
    // },
];

const MENU_ADMIN = [
    // {
    //     "id_modulo": 100,
    //     "name": "Panel",
    //     "columns": "1",
    //     "rowsPerColumn": 1,
    //     "key_menu": "panel-admin",
    //     "route": "/pages/dashboard/administrator",
    //     "icon": "",
    //     "collapse": null
    // },
];

const ID_CALI_FINAL = 1;
const ID_CALI_PARTIAL = 2;
const ID_CALI_WORK = 3;
const ID_CALI_PRACTICE = 4;

// Keys Services
const KEY_SERVICE_ADMISION = {
    "key_service": 1,
    "name": "Admisión",
    "image_name": ROOT_SERVER + '/static/images/academyc/admision.jpg'
};
const KEY_SERVICE_NIVELACION = {
    "key_service": 2,
    "name": "Nivelación",
    "image_name": ROOT_SERVER + '/static/images/academyc/nivelacion.png'
};
const KEY_SERVICE_MATRICULA = {
    "key_service": 3,
    "name": "Matrícula",
    "image_name": ROOT_SERVER + '/static/images/academyc/matricula.jpg'
};
const KEY_SERVICE_TRAMITE = {
    "key_service": 4,
    "name": "Trámite",
    "image_name": ROOT_SERVER + '/static/images/academyc/tramite.jpg'
};
const KEY_SERVICE_CONVOCATORIA = {
    "key_service": 5,
    "name": "Convocatoria",
    "image_name": ROOT_SERVER + '/static/images/academyc/convocatoria.jpg'
};
const KEY_SERVICE_TEST = {
    "key_service": 6,
    "name": "Test",
    "image_name": ROOT_SERVER + '/static/images/academyc/test.jpg'
};

const DATA_SEXO = [
    {
        id_sexo: "0",
        nom_sexo: "Femenino"
    },
    {
        id_sexo: "1",
        nom_sexo: "Masculino"
    }
];

const DATA_PERIOD = [
    {
        id_periodo: 1,
        periodo: "I",
        descripcion: "Primer Semestre",
    },
    {
        id_periodo: 2,
        periodo: "II",
        descripcion: "Segundo Semestre ",
    },
    {
        id_periodo: 3,
        periodo: "Verano",
        descripcion: "Verano",
    }
];

const DATA_COURSE_TYPE = [
    {
        id_tipo: 1,
        nombre: "NORMAL"
    },
    {
        id_tipo: 2,
        nombre: "MULTICICLO"
    }
]

const DATA_CALIFICACION = [
    {
        id_calificacion: 1,
        nombre: "Trabajo"
    },
    {
        id_calificacion: 2,
        nombre: "Examne parcial"
    },
    {
        id_calificacion: 3,
        nombre: "Examen final"
    }
];

function dateFormatYYMMDD(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return year + "-" + month + "-" + day;
}

function getYears(minYear, maxYear) {
    let years= [];
    if(maxYear === 0) {
        maxYear = new Date().getFullYear();
    }
    if(minYear === 0) {
        minYear = 2000;
    }
    for(var i = maxYear; i >= minYear; i--) {
        var oneYear = {
            year_id: i,
            name: i
        }
        years.push(oneYear);
    }
    return years;
}

const getUserAuth = () => {
    let hasConn = sessionStorage.getItem('hasConn');
    let idUser = "";
    let strToken = "";
    let userName = "";
    let fotoUser = "";
    let nomCargo = "";
    if(hasConn !== null){
        let jsonHasConn = JSON.parse(hasConn);
        idUser = jsonHasConn['id_rand'];
        strToken = jsonHasConn['id_cook'];
        userName = jsonHasConn['username'];
        fotoUser = jsonHasConn['foto_user'];
        nomCargo = jsonHasConn['nom_rol'];
    }
    return [idUser, strToken, userName, fotoUser, nomCargo];
}

export {
    MESSAGE_NOT_CONNECT_SERVER,
    URL_SERVICE,
    ROOT_SERVER,
    ROOT_GALERIA,
    ROOT_FILES_PDF,
    MENU_INIT,
    INSTITUTION_SUBTITLE,
    MENU_ADMIN,
    KEY_SERVICE_ADMISION,
    KEY_SERVICE_NIVELACION,
    KEY_SERVICE_MATRICULA,
    KEY_SERVICE_TRAMITE,
    KEY_SERVICE_CONVOCATORIA,
    KEY_SERVICE_TEST,
    DATA_SEXO,
    DATA_PERIOD,
    DATA_COURSE_TYPE,
    DATA_CALIFICACION,
    ID_CALI_FINAL,
    ID_CALI_PARTIAL,
    ID_CALI_WORK,
    ID_CALI_PRACTICE,
    ID_INSTITUCION,
    NOM_CORTO_INSTITUCION1,
    NOM_CORTO_INSTITUCION2,
    LOGO_INSTITUCION1,
    LOGO_INSTITUCION2,
    dateFormatYYMMDD,
    getYears,
    getUserAuth,
}