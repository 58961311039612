import { Box, Divider, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllMatricula } from "redux/actions/notesAction";
// import RegisterSchool from "./sections/RegisterSchool";
// import SchoolList from "./sections/SchoolList";
import { getAllScholls } from "redux/actions/notesAction";
import Matriculas from "./sections/Matriculas";
import Classroom from "./sections/Classroom";
import Inscription from "./sections/Inscription";
import { getAllClassrooms } from "redux/actions/notesAction";
import { getAllTeachers } from "redux/actions/notesAction";
import { getAllModules } from "redux/actions/notesAction";
import { get_all_courses_action } from "redux/actions/notesAction";

const AcademicCicle = () => {
    const dispatch = useDispatch();
    const [actionCourse, setActionCourse] = useState("list");

    const handleChangeAction = (action) => {
        setActionCourse(action);
    }

    useEffect(async () => {
        dispatch(get_all_courses_action([]));
        dispatch(getAllScholls());
        dispatch(getAllTeachers());
        await dispatch(getAllMatricula());
        await dispatch(getAllClassrooms());
        await dispatch(getAllModules());
    });

    return(
        <>
        <Box sx={{background: "#FFF"}}>
        <Grid container>
            <Grid item xs={12} pb={4}>
                <Matriculas />
            </Grid>
            <Grid item xs={12}>
                <Classroom />
            </Grid>
            {/* <Grid item xs={12} lg={12} pt={5}>
                <Inscription />
            </Grid> */}
        </Grid>
        </Box>
        </>
    )
}

export default AcademicCicle;