import { Divider } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllStudents } from "redux/actions/notesAction";
import RegisterStudent from "./sections/RegisterStudent";
import StudentList from "./sections/StudentList";

const Students = () => {
    const dispatch = useDispatch();
    const [actionStudent, setActionStudent] = useState("list");

    const handleChangeAction = (action) => {
        setActionStudent(action);
    }

    useEffect(async () => {
        await dispatch(getAllStudents());
    });

    return(
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                GESTION DE ALUMNOS
            </MKTypography>
            <Divider />
            {actionStudent === "list" && (<StudentList onChangeAction={handleChangeAction} />)}
            {actionStudent === "register" && (<RegisterStudent onChangeAction={handleChangeAction} />)}
        </>
    )
}

export default Students;