import { createStore, combineReducers, compose,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { systemParamtsReducer, notifReducer, severalReducer } from './modules/severalReducer';
import { userReducer, menuReducer } from './modules/userReducer';
import { 
    studentReducer, 
    teacherReducer, 
    courseReducer, 
    schoolReducer, 
    planReducer, 
    planCursoReducer ,
    matriculaReducer,
    classroomReducer,
    moduleReducer,
    cicleReducer,
    calificationTypeReducer
} from './modules/notesReducer';
import { generalDataReducer } from './modules/generalDataReducer';

const reducers = combineReducers({
    systemParamtsReducer,
    notifReducer,
    severalReducer,
    menuReducer,
    userReducer,
    generalDataReducer,
    studentReducer,
    teacherReducer,
    courseReducer,
    schoolReducer,
    planReducer,
    planCursoReducer,
    matriculaReducer,
    classroomReducer,
    moduleReducer,
    cicleReducer,
    calificationTypeReducer,
});

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
)