import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const TeacherSelect = (props) => {
    const { dataTeachers, onSelectTeacher, teacherSelected } = props;
    const [values, setValues] = useState({
        id_profesor: 0
    });
    let teacherName = "";

    const handleChange = e => {
        let idTeacher = parseInt(e.target.value);
        const oneTeacher = dataTeachers.filter(p=> p.id_profesor === idTeacher);
        setValues({ id_profesor: idTeacher });
        onSelectTeacher(oneTeacher[0]);
    }

    useEffect(() => {
        setValues({ id_profesor: teacherSelected });
    }, [teacherSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Profesor</InputLabel>
            <Select
                name="id_profesor"
                value={values.id_profesor}
                label="Profesor"
                required
                onChange={handleChange}
            >
                {dataTeachers && dataTeachers.map(teacher => {
                    let keyTeacher = "key" + teacher.id_profesor;
                    teacherName = teacher.nom_persona + " " + teacher.ape_paterno + " " + teacher.ape_materno;
                    return(
                        <MenuItem value={teacher.id_profesor} key={keyTeacher} >{teacherName}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default TeacherSelect;