// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

// react-redux
import { Provider } from 'react-redux';
import { store } from './redux/reducer';

// Helpers
import LoginRouter from "routers/LoginRouter";

// styles
import './styles/index.css';

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LoginRouter />
      </ThemeProvider>
    </Provider>
  );
}

// (Problemas de dependencia en los paquetes) yarn add --dev eslint-config-react-app