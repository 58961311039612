import { Box, Divider, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllMatricula } from "redux/actions/notesAction";
// import RegisterSchool from "./sections/RegisterSchool";
// import SchoolList from "./sections/SchoolList";
import { getAllScholls } from "redux/actions/notesAction";
import RegisterNotes from "./sections/RegisterNotes";
import { getAllClassrooms } from "redux/actions/notesAction";
import { getAllTeachers } from "redux/actions/notesAction";
import { getAllStudents } from "redux/actions/notesAction";
import { getAllCicles } from "redux/actions/notesAction";
import NotesStudent from "./sections/NotesStudent";
import { getAllCalificationTypes } from "redux/actions/notesAction";
import { ID_INSTITUCION } from "helpers/helperInit";

const Notes = ({rol}) => {
    const dispatch = useDispatch();
    const [actionCourse, setActionCourse] = useState("list");

    const handleChangeAction = (action) => {
        setActionCourse(action);
    }

    useEffect(async () => {
        dispatch(getAllScholls());
        dispatch(getAllCicles());
        await dispatch(getAllMatricula());
        await dispatch(getAllClassrooms());
        await dispatch(getAllStudents());
        dispatch(getAllCalificationTypes());
    });

    return(
        <>
        <Box sx={{background: "#FFF"}}>
        <Grid container>
            <Grid item xs={12}>
                {rol === "notes-teacher" &&(<RegisterNotes />)}
                {rol === "notes-student" &&(<NotesStudent />)}
            </Grid>
        </Grid>
        </Box>
        </>
    )
}

export default Notes;