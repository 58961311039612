import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from 'classnames';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Notification from 'components/Notification';

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

// Helpers
import axios from 'axios';
import { URL_SERVICE, ROOT_SERVER, INSTITUTION_SUBTITLE } from 'helpers/helperInit';
import { ID_INSTITUCION } from 'helpers/helperInit';
import { NOM_CORTO_INSTITUCION1 } from 'helpers/helperInit';
import { NOM_CORTO_INSTITUCION2 } from 'helpers/helperInit';

// actions reducer
import { openNotifAction, closeNotifAction } from 'redux/actions/severalAction';
import SimpleHeader from "examples/Headers/SimpleHeader";
import { LOGO_INSTITUCION1 } from "helpers/helperInit";
import { LOGO_INSTITUCION2 } from "helpers/helperInit";
// import { get_user_auth_action } from 'redux/actions/userAction';


function SignInBasic() {
  const dispatch = useDispatch();
  const reducerNotif = "notifReducer";
  const reducerGeneralData = "generalDataReducer";
  const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
  const [rememberMe, setRememberMe] = useState(false);
  const [data, setData] = useState({
    username: "",
    userpass: ""
  });
  const messageNotif = useSelector(state => state[reducerNotif].notif_msg);
  const variantNotif = useSelector(state => state[reducerNotif].notif_variant);
  const typeNotif = useSelector(state => state[reducerNotif].notif_type);
  const bgImage = ROOT_SERVER + '/static/images/logo/' + (ID_INSTITUCION === 1 ? LOGO_INSTITUCION1 : LOGO_INSTITUCION2);
  console.log("bgImage...", bgImage)
  
  const closeNotif = () => dispatch(closeNotifAction());

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const onLogin = (event) => {
    event.preventDefault();
    const username = data.username;
    // let dataLogin = { "username": username, "password": data.userpass };
    let dataLogin = JSON.stringify({ "username": username, "password": data.userpass });
      axios(URL_SERVICE + "usuario/login/", {
        headers: {
          'Content-Type': 'Application/Json',
        },
        method: "POST",
        data: dataLogin
      })
      .then(res => {
        const data = res.data;
        if(data.success) {
          let id_navigation = {
            'idPersona': data.idPersona,
            'idAlumno': data.idAlumno,
            'id_rand': data.userid,
            "username": data.username,
            "foto_user": data.foto_user,
            "nom_rol": data.nom_rol,
            'id_cook': data.token
          };
          sessionStorage.setItem("hasConn", JSON.stringify(id_navigation));
          window.location = ROOT_SERVER + '/pages/dashboard/administrator';
        } else {
          dispatch(openNotifAction([data.msg_rpta, "error", 'filled']));
          setData({ username: username, userpass: "" });
        }
      })
      .catch(err => {
        console.log("error", err);
      });
  }

  useEffect(() => {
    document.title = ID_INSTITUCION === 1 ? NOM_CORTO_INSTITUCION1 : NOM_CORTO_INSTITUCION2;
  });

  return (
    <>
      <Notification close={() => closeNotif()} message={messageNotif} notiftype={typeNotif} notifvariant={variantNotif} />
      <Grid container className="bg-primary">
        <Grid item xs={6} style={{height: '100%'}} alignContent='center' alignItems={'center'} alignSelf='center' textAlign={'center'}>
          <div>
            <img src={bgImage} width='150px' style={{ borderRadius: '15px' }} />
            <div className="linea-division"></div>
            {data_general_data.length > 0 && (
              <>
              <MKTypography 
                // className='color_primary'
                color='white'
                style={{
                  fontWeight: 700,
                  // color: '#FFF',
                  fontSize: '25px',
                  fontFamily: 'Georgia'
                }}
              >
                {data_general_data[0].nombre_institucion}</MKTypography>
              <MKTypography
                className="color-secondary"
                style={{
                  fontSize: '12px',
                  fontFamily: 'Arial',
                }}
              >
                  {INSTITUTION_SUBTITLE}
              </MKTypography>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={6} className="bg-primary-opacity">
          <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative">
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item xs={8}>
                <Card>
                  <MKBox pt={4} pb={3} px={3}>
                    <MKBox component="form" role="form">
                      <MKBox mb={2}>
                        <MKInput type="email" label="Username" fullWidth name="username" value={data.username} onChange={handleChange} />
                      </MKBox>
                      <MKBox mb={2}>
                        <MKInput type="password" label="Password" fullWidth name="userpass" value={data.userpass} onChange={handleChange} />
                      </MKBox>
                      <MKBox mt={4} mb={1}>
                        <MKButton variant="gradient" color="info" fullWidth onClick={onLogin} type="submit">
                          sign in
                        </MKButton>
                      </MKBox>
                      <MKBox mt={3} mb={1} textAlign="center">
                        <MKTypography variant="button" color="text">
                          <MKTypography
                            component={Link}
                            to={"/"}
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                          >
                            Volver
                          </MKTypography>
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox 
        mt={3}
        style={{
          borderTop: '1px solid #FFF'
        }}
      >
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SignInBasic;
