import { Divider } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllPlanes } from "redux/actions/notesAction";
import { getAllCourses } from "redux/actions/notesAction";
import RegisterPlan from "./sections/RegisterPlan";
import PlanList from "./sections/PlanList";
import { getAllScholls } from "redux/actions/notesAction";

const Plan = () => {
    const dispatch = useDispatch();
    const [actionPlan, setActionPlan] = useState("list");

    const handleChangeAction = (action) => {
        setActionPlan(action);
    }

    useEffect(async () => {
        dispatch(getAllScholls());
        // dispatch(getAllCourses());
        await dispatch(getAllPlanes());
    });

    return(
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                GESTION DE PLAN
            </MKTypography>
            <Divider />
            {actionPlan === "list" && (<PlanList onChangeAction={handleChangeAction} />)}
            {actionPlan === "register" && (<RegisterPlan onChangeAction={handleChangeAction} />)}
        </>
    )
}

export default Plan;