import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';

import MKTypography from "components/MKTypography";
import { set_one_classroom_action } from "redux/actions/notesAction";
import { Divider, IconButton, Tooltip } from "@mui/material";
import SchoolSelect from "../../Schools/sections/SchoolSelect";
import { getAllCoursesSchool } from "redux/actions/notesAction";
import CourseSelect from "../../Courses/sections/CourseSelect";
import TeacherSelect from "../../Teachers/sections/TeacherSelect";
import { openNotifAction } from "redux/actions/severalAction";
import { saveNewClassroom } from "redux/actions/notesAction";
import { getAllClassrooms } from "redux/actions/notesAction";
import ConfirmationDialog from "components/ConfirmationDialog";
import { saveDeleteClassroom } from "redux/actions/notesAction";
import ModuleSelect from "../../Module/Sections/ModuleSelect";
import { getOtherAllCoursesSchool } from "redux/actions/notesAction";

const Classroom = () => {
    const dispatch = useDispatch();
    const reducerSchool = "schoolReducer";
    const reducerClassroom = "classroomReducer";
    const reducerMatricula = "matriculaReducer";
    const reducerCourse = "courseReducer";
    const reducerTeacher = "teacherReducer";
    const reducerModule = "moduleReducer";
    const data_schools = useSelector(state => state[reducerSchool].data_schools);
    const data_classrooms = useSelector(state => state[reducerClassroom].data_classrooms);
    const data_modules = useSelector(state => state[reducerModule].data_modules);
    const one_classroom = useSelector(state => state[reducerClassroom].one_classroom);
    const one_matricula = useSelector(state => state[reducerMatricula].one_matricula);
    const data_courses = useSelector(state => state[reducerCourse].data_courses);
    const data_teachers = useSelector(state => state[reducerTeacher].data_teachers);
    const [valuesClassroom, setValuesClassroom] = useState({
        id_aula: 0,
        capacidadmax: 0,
        capacidadmin: 0,
        fk_matricula: 0,
        fk_profesor: 0,
        fk_curso: 0,
        fk_escuela: 0,
    });
    const [deleteClassroom, setDeleteClassroom] = useState({
        id_aula: 0
    });
    const [dataClassroom, setDataClassroom] = useState(null);
    const [dataOrigCoursesSchool, setDataOrigCoursesSchool] = useState(null);
    const [dataCoursesSchool, setDataCoursesSchool] = useState(null);
    const [oneClassroom, setOneClassroom] = useState(null);
    const [defaultSchool, setDefaultSchool] = useState(0);
    const [defaultModule, setDefaultModule] = useState(0);
    const [openDialogAula, setOpenDialogAula] = useState(false);
    const [reload, setReload] = useState(false);
    const [expandCourses, setExpandCourses] = useState(true);
    const [expandClassroom, setExpandClassroom] = useState(true);
    const [dispPage, setDispPage] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsDispPerPage, setRowsDispPerPage] = useState(5);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    let nomProf = "";
    let flgShowBtnAper = false;
    let showCourse = false;

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }
    
    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }
    
    const handleDispChangePage = (e, newPage) => {
        setDispPage(newPage);
    }
    
    const handleChangeRowsDispPerPage = (e) => {
        setRowsDispPerPage(e.target.value);
        setDispPage(0)
    }

    const handleChangeExpandClassroom = () => {
        // setExpanded(!expanded);
    };

    const handleSelectClassroom = (classroom) => {
        dispatch(set_one_classroom_action(classroom));
        // setExpanded(!expanded);
    }

    const handleSelectSchool = async (school) => {
        setDefaultSchool(school.id_escuela);
        let newList = [];
        if(one_matricula !== null) {
            newList = await data_classrooms.filter(item => parseInt(item.id_matricula) === parseInt(one_matricula.id_matricula) && parseInt(item.fk_escuela) === parseInt(school.id_escuela));
            setDataClassroom(newList);
        }

        await setValuesClassroom({...valuesClassroom, fk_escuela: school.id_escuela});
        // await dispatch(getAllCoursesSchool(school.id_escuela));
        const dataReturn = await getOtherAllCoursesSchool(school.id_escuela);
        setDataCoursesSchool(dataReturn.data);
        setDataOrigCoursesSchool(dataReturn.data);
        // setValues({...values, fk_escuela: school.ikd_escuela})
    }
    
    const handleSelectModule = async (module) => {
        setDefaultModule(module.id_modulo);
        const newList = dataOrigCoursesSchool.filter(item => parseInt(item.id_modulo) === parseInt(module.id_modulo));
        setDataCoursesSchool(newList);
        // let newList = [];
        // if(one_matricula !== null) {
        //     if(dataClassroom !== null) {
        //         data_courses = await data_courses.filter(item => parseInt(item.id_modulo) === parseInt(module.id_modulo));
        //         // setDataClassroom(newList);
        //     }
        // }

        // await setValuesClassroom({...valuesClassroom, fk_escuela: school.id_escuela});
        // await dispatch(getAllCoursesSchool(school.id_escuela));
        // setValues({...values, fk_escuela: school.ikd_escuela})
    }

    const handleOpenNewClassroom = (course) => {
        if(one_matricula === null) {
            dispatch(openNotifAction(["Seleccione matricula", "error", 'filled']));
            return;
        }
        if(one_matricula.estadoMatricula === 2) {
            dispatch(openNotifAction(["No es posible agregar aula a una matrícula que se encuentra cerrada", "error", 'filled']));
            return;
        }
        setOpenDialogAula(true);
        valuesClassroom.id_aula = 0;
        valuesClassroom.capacidadmax = 0;
        valuesClassroom.capacidadmin = 0;
        valuesClassroom.fk_profesor = 0;
        valuesClassroom.fk_curso = course.id_curso;
        // valuesClassroom.fk_escuela = 0;
    }

    const handleChangeValuesClassroom = (e) => {
        setValuesClassroom({...valuesClassroom, [e.target.name]: e.target.value});
    }

    // const handleChangeSchool = async (school) => {
    //     setValuesClassroom({...valuesClassroom, fk_escuela: school.id_escuela});
    //     await dispatch(getAllCoursesSchool(school.id_escuela));
    // }
    
    // const handleChangeCourse = async (course) => {
    //     setValuesClassroom({...valuesClassroom, fk_curso: course.id_curso});
    // }
    
    const handleChangeTeacher = async (teacher) => {
        setValuesClassroom({...valuesClassroom, fk_profesor: teacher.id_profesor});
    }

    const handleSaveAula = async() => {
        if(valuesClassroom.fk_matricula === 0 || valuesClassroom.fk_profesor === 0 || valuesClassroom.fk_curso === 0 || valuesClassroom.fk_escuela === 0) {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        if(valuesClassroom.capacidadmin === "") {
            valuesClassroom.capacidadmin = 0
        }
        if(valuesClassroom.capacidadmax === "") {
            valuesClassroom.capacidadmax = 0
        }
        const rptaSave = await saveNewClassroom(valuesClassroom);
        if(rptaSave.success) {
            dispatch(openNotifAction([rptaSave.data[0].msg, "success", 'filled']));
            await dispatch(getAllClassrooms());
            setOpenDialogAula(false);
            setReload(!reload);
        } else {
            dispatch(openNotifAction([rptaSave.data[0].msg, "error", 'filled']));
        }
    }

    const handleCloseDialog = () => {
        setOpenDialogAula(false);
    };

    const handleDeleteClassroom = (classroom) => {
        setDeleteClassroom({
            id_aula: parseInt(classroom.id_aula)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Aula",
            content: "¿Seguro de Eliminar Aula seleccionada?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataClassroom = {
                "id_aula": deleteClassroom.id_aula
            };
			const rptaSave = await saveDeleteClassroom(newDataClassroom);
            if(rptaSave.success) {
                dispatch(openNotifAction(["Eliminación exitosa!!!", "success", 'filled']));
                await dispatch(getAllClassrooms());
                dispatch(set_one_classroom_action(null));
                setReload(!reload);
            } else {
                dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    const initData = async () => {
        if(one_matricula !== null) {
            let newList = [];
            if(defaultSchool > 0) {
                newList = data_classrooms.filter(item => parseInt(item.id_matricula) === parseInt(one_matricula.id_matricula));
                newList = newList.filter(item => parseInt(item.fk_escuela) === parseInt(defaultSchool));
            }
            // if(defaultModule > 0) {
            //     newList = newList.filter(item => parseInt(item.id_modulo) === parseInt(defaultModule));
            // }
            valuesClassroom.fk_matricula = one_matricula.id_matricula;
            await setDataClassroom(newList);
            // setExpanded(true);
        }
        if(one_classroom !== null) {
            setOneClassroom(one_classroom);
        }
    }

    useEffect(() => {
        initData();
    }, [one_matricula, one_classroom, reload]);

    return(
        <>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <SchoolSelect dataSchools={data_schools} onSelectSchool={handleSelectSchool} schoolSelected={defaultSchool} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <ModuleSelect dataModules={data_modules} onSelectModule={handleSelectModule} moduleSelected={defaultModule} />
                    </Grid>
                    <Grid item xs={12}>
                    <Accordion expanded={expandCourses} sx={{border: '1px solid #CCC'}}>
                        <AccordionDetails>
                            <Table>
                                <TableBody>
                                    <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                        <TableCell align="left">Curso</TableCell>
                                        <TableCell align="left">Modulo</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                    {dataCoursesSchool !== null && dataCoursesSchool
                                    .slice(dispPage * rowsDispPerPage, dispPage * rowsDispPerPage + rowsDispPerPage)
                                    .map((course, index) => {
                                        // nomProf = "";
                                        // flgShowBtnAper = true;
                                        // showCourse = true;
                                        // let existCourse = [];
                                        // if(dataClassroom !== null) {
                                        //     existCourse = dataClassroom.filter(item => item.curso[0].id_curso === course.id_curso);
                                        //     if(existCourse.length > 0) {
                                        //         flgShowBtnAper = false;
                                        //     }
                                        // }
                                        // if(defaultModule > 0) {
                                        //     if(course.id_modulo !== defaultModule) {
                                        //         showCourse = false;
                                        //     }
                                        // }
                                        // if(!!showCourse) {
                                            return(
                                                <TableRow 
                                                    key={"course" + index} 
                                                    hover 
                                                    sx={{cursor: 'pointer'}} 
                                                >
                                                    <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{course.curso}</TableCell>
                                                    <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{course.modulo}</TableCell>
                                                    <TableCell align='right'>
                                                        {/* {!!flgShowBtnAper && ( */}
                                                            <Tooltip title='Aperturar'>
                                                                <IconButton color='text' onClick={() => handleOpenNewClassroom(course)}><ArrowCircleRightIcon fontSize='small' /></IconButton>
                                                            </Tooltip>
                                                        {/* )} */}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        // }
                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={[5,10,50,100]}
                                page={dispPage}
                                rowsPerPage={rowsDispPerPage}
                                count={dataCoursesSchool ? dataCoursesSchool.length : 0 }
                                labelRowsPerPage=''
                                onPageChange={handleDispChangePage}
                                onRowsPerPageChange={handleChangeRowsDispPerPage}
                            />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={7}>
                <MKTypography sx={{fontSize: '17px', fontStyle: 'italic'}}>
                    Aulas aperturadas
                </MKTypography>
                <Accordion expanded={expandClassroom} onChange={handleChangeExpandClassroom} sx={{border: '1px solid #CCC'}}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Grid container pl={2}>
                            <Grid item xs={12}>
                                {oneClassroom !== null ? (
                                    <MKTypography display='inline' fontWeight='bold' sx={{fontSize: '15px'}}>
                                        Aula: {oneClassroom !== null ? oneClassroom.escuela[0].escuela + " / " + oneClassroom.curso[0].curso + " / " + oneClassroom.profesor[0].nom_persona + " " + oneClassroom.profesor[0].ape_paterno : ""}
                                    </MKTypography>
                                ) : (
                                    <MKTypography display='inline' sx={{fontSize: '15px', fontStyle: 'italic', color: '#CCC'}}>
                                        Seleccione aula
                                    </MKTypography>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableBody>
                                <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                                    {/* <TableCell align="left">Escuela</TableCell> */}
                                    <TableCell align="left">Curso</TableCell>
                                    <TableCell align="left">Profesor</TableCell>
                                    <TableCell align="left">C.Min</TableCell>
                                    <TableCell align="left">C.Max</TableCell>
                                    <TableCell align="right">
                                        {/* <Tooltip title='Registrar nueva aula'>
                                            <IconButton color='text' onClick={handleOpenNewClassroom} ><InsertDriveFileIcon fontSize='small' /></IconButton>
                                        </Tooltip> */}
                                    </TableCell>
                                </TableRow>
                                {dataClassroom !== null && dataClassroom
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(classroom => {
                                    nomProf = classroom.profesor[0].nom_persona + " " + classroom.profesor[0].ape_paterno;
                                    return(
                                        <TableRow 
                                            key={"classroom" + classroom.id_aula} 
                                            hover 
                                            sx={{cursor: 'pointer'}} 
                                            onClick={() => handleSelectClassroom(classroom)}
                                        >
                                            {/* <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.escuela[0].escuela}</TableCell> */}
                                            <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.curso[0].curso}</TableCell>
                                            <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{nomProf}</TableCell>
                                            <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.capacidadmin}</TableCell>
                                            <TableCell align="center" sx={{verticalAlign: 'top', fontSize: '14px'}}>{classroom.capacidadmax}</TableCell>
                                            <TableCell align='right'>
                                                <Tooltip title='Anular'>
                                                    <IconButton color='text' onClick={() => handleDeleteClassroom(classroom)}><DeleteIcon fontSize='small' /></IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={[5,10,50,100]}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        count={dataClassroom ? dataClassroom.length : 0 }
                        labelRowsPerPage=''
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
        
        <ConfirmationDialog 
            open={stateConfirmDialog.open}
            title={stateConfirmDialog.title}
            content={stateConfirmDialog.content}
            onClose={handleCloseConfirmDialog}
        />

        <Dialog onClose={handleCloseDialog} open={openDialogAula} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={3} pt={5}>
                    <Grid item xs={12}>
                        Matricula: {one_matricula !== null ? one_matricula.anio.toString() + " - " + one_matricula.periodo : ""}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <TeacherSelect dataTeachers={data_teachers} onSelectTeacher={handleChangeTeacher} teacherSelected={0} />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            type='number'
                            variant="standard"
                            label="Cap. Mínima"
                            name="capacidadmin"
                            value={valuesClassroom.capacidadmin}
                            onChange={handleChangeValuesClassroom}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            type='number'
                            variant="standard"
                            label="Cap. Máxima"
                            name="capacidadmax"
                            value={valuesClassroom.capacidadmax}
                            onChange={handleChangeValuesClassroom}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSaveAula} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>


        {/* <Dialog onClose={handleCloseDialog} open={openDialogAula} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12} lg={4}>
                        Matricula: {one_matricula !== null ? one_matricula.anio.toString() + " - " + one_matricula.periodo : ""}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <SchoolSelect dataSchools={data_schools} onSelectSchool={handleChangeSchool} schoolSelected={0} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <CourseSelect dataCourses={data_courses} onSelectCourse={handleChangeCourse} courseSelected={0} />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <TeacherSelect dataTeachers={data_teachers} onSelectTeacher={handleChangeTeacher} teacherSelected={0} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            type='number'
                            variant="standard"
                            label="Cap. Mínima"
                            name="capacidadmin"
                            value={valuesClassroom.capacidadmin}
                            onChange={handleChangeValuesClassroom}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            type='number'
                            variant="standard"
                            label="Cap. Máxima"
                            name="capacidadmax"
                            value={valuesClassroom.capacidadmax}
                            onChange={handleChangeValuesClassroom}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSaveAula} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog> */}

        </>
    );
}

export default Classroom;