import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, TextField, Divider } from "@mui/material";
import { openNotifAction } from "redux/actions/severalAction";
import { getAllCourses } from "redux/actions/notesAction";
import { saveNewCourse } from "redux/actions/notesAction";
import MKTypography from "components/MKTypography";
import SchoolSelect from "../../Schools/sections/SchoolSelect";
import CourseTypeSelect from "components/SeveralSelect/CourseTypeSelect";
import { DATA_COURSE_TYPE } from "helpers/helperInit";

const RegisterCourse = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerSchool = "schoolReducer";
    const reducerCourse = "courseReducer";
    const data_schools = useSelector(state => state[reducerSchool].data_schools);
    const one_course = useSelector(state => state[reducerCourse].one_course);
    const [defaultSchool, setDefaultSchool] = useState(0);
    const [defaultCourseType, setDefaultCourseType] = useState(1);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        id_curso: 0,
        id_escuela: 0,
        curso: "",
        tipo: 1
    });

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleSelectSchool = (school) => {
        setDefaultSchool(school.id_escuela);
        setValues({...values, id_escuela: school.id_escuela});
    }
    
    const handleSelectCourseType = (courseType) => {
        setDefaultCourseType(courseType.id_tipo);
        setValues({...values, tipo: courseType.id_tipo});
    }

    const handleSaveCourse = async () => {
        if(values.curso === "") {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        const rptaSave = await saveNewCourse(values);
        if(rptaSave.success) {
            dispatch(openNotifAction(["Registro exitoso!!!", "success", 'filled']));
            dispatch(getAllCourses());
            onChangeAction("list");
        } else {
            dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
        }
    }

    const handleSetAction = () => {
        onChangeAction("list");
    }

    const initData = () => {
        if(one_course !== null) {
            setValues({
                id_curso: one_course.id_curso,
                id_escuela: one_course.id_escuela,
                curso: one_course.curso,
                tipo: one_course.tipo,
            });
            setDefaultSchool(one_course.id_escuela);
            setDefaultCourseType(one_course.tipo);
            setTitle("Actualizando registro");
        }
    }

    useEffect(() => {
        initData();
    }, [one_course]);

    return(
        <Box>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <MKTypography className='color-secondary' fontWeight='bold'>
                        {title}
                    </MKTypography>
                </Grid>
                <Grid item xs={12} lg={6} textAlign='right'>
                    <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSaveCourse}>Guardar</Button>
                        <Button onClick={handleSetAction}>Cancelar</Button>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                    <SchoolSelect dataSchools={data_schools} onSelectSchool={handleSelectSchool} schoolSelected={defaultSchool} />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <CourseTypeSelect dataCourseTypes={DATA_COURSE_TYPE} onSelectCourseType={handleSelectCourseType} courseTypeSelected={defaultCourseType} />
                </Grid>
                <Grid item xs={12} lg={7}>
                    <TextField 
                        variant="standard"
                        name="curso"
                        value={values.curso}
                        placeholder="Nombre del curso"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default RegisterCourse;