import { Box, Divider, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllMatricula } from "redux/actions/notesAction";
// import RegisterSchool from "./sections/RegisterSchool";
// import SchoolList from "./sections/SchoolList";
import { getAllScholls } from "redux/actions/notesAction";
import RegisterInscription from "./sections/RegisterInscription";
import { getAllClassrooms } from "redux/actions/notesAction";
import { getAllTeachers } from "redux/actions/notesAction";
import { getAllStudents } from "redux/actions/notesAction";
import { getAllCicles } from "redux/actions/notesAction";

const Inscription = () => {
    const dispatch = useDispatch();
    const [actionCourse, setActionCourse] = useState("list");

    const handleChangeAction = (action) => {
        setActionCourse(action);
    }

    useEffect(async () => {
        dispatch(getAllScholls());
        dispatch(getAllTeachers());
        dispatch(getAllCicles());
        await dispatch(getAllMatricula());
        await dispatch(getAllClassrooms());
        await dispatch(getAllStudents());
    });

    return(
        <>
        <Box sx={{background: "#FFF"}}>
        <Grid container>
            {/* <Grid item xs={12} lg={4} pr={1}>
                <Matriculas />
            </Grid> */}
            <Grid item xs={12}>
                <RegisterInscription />
            </Grid>
            {/* <Grid item xs={12} lg={12} pt={5}>
                <Inscription />
            </Grid> */}
        </Grid>
        </Box>
        </>
    )
}

export default Inscription;