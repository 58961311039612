import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import MKTypography from "components/MKTypography";
import { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { set_one_matricula_action } from 'redux/actions/notesAction';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { getAllMatricula } from 'redux/actions/notesAction';
import YearSelect from 'components/SeveralSelect/YearSelect';
import { getYears } from 'helpers/helperInit';
import PeriodoSelect from 'components/SeveralSelect/PeriodoSelect';
import { DATA_PERIOD } from 'helpers/helperInit';
import { openNotifAction } from 'redux/actions/severalAction';
import { saveNewMatricula } from 'redux/actions/notesAction';

const Matriculas = () => {
    const dispatch = useDispatch();
    const reducerMatricula = "matriculaReducer";
    let descMatricula = "";
    const data_matriculas = useSelector(state => state[reducerMatricula].data_matriculas);
    const one_matricula = useSelector(state => state[reducerMatricula].one_matricula);
    const [dataMatriculas, setDataMatriculas] = useState(data_matriculas);
    const [oneMatricula, setOneMatricula] = useState(null);
    const [valuesMatricula, setValuesMatricula] = useState({
        id_maricula: 0,
        fk_periodo: 0,
        anio: 0,
    });
    const [openDialogMatricula, setOpenDialogMatricula] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const maxYear = new Date().getFullYear() + 1;
    const arrYears = getYears(2020, maxYear);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const handleSelectMatricula = (matricula) => {
        dispatch(set_one_matricula_action(matricula));
        setExpanded(!expanded);
    }

    const handleOpenNewMatricula = () => {
        setOpenDialogMatricula(true);
        valuesMatricula.id_maricula = 0;
        valuesMatricula.fk_periodo = 0;
        valuesMatricula.anio = 0;
    }

    const handleSelectNewYear = (year) => {
        setValuesMatricula({...valuesMatricula, anio: year.year_id});
    }
    
    const handleChangePeriod = (period) => {
        setValuesMatricula({...valuesMatricula, fk_periodo: period.id_periodo});
    }

    const handleSaveMatricula = async() => {
        if(valuesMatricula.fk_periodo === 0 || valuesMatricula.anio === 0) {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        const rptaSave = await saveNewMatricula(valuesMatricula);
        if(rptaSave.success) {
            dispatch(openNotifAction([rptaSave.data[0].msg, "success", 'filled']));
            await dispatch(getAllMatricula());
            setOpenDialogMatricula(false);
            // setReload(!reload);
        } else {
            dispatch(openNotifAction([rptaSave.data[0].msg, "error", 'filled']));
        }
    }

    const handleCloseDialog = () => {
        setOpenDialogMatricula(false);
    };


    const initData = () => {
        setDataMatriculas(data_matriculas);
        if(one_matricula !== null) {
            setOneMatricula(one_matricula);
        }
    }

    useEffect(() => {
        initData();
    }, [data_matriculas, one_matricula]);

    return(
        <>
        <MKTypography sx={{fontSize: '17px', fontStyle: 'italic'}}>
            Ciclo académico
        </MKTypography>
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Grid container pt={1}>
                    <Grid item xs={12}>
                        {oneMatricula !== null ? (
                            <>
                            <MKTypography display='inline' fontWeight='bold' sx={{fontSize: '15px'}}>
                                Matrícula: {oneMatricula.anio.toString() + " - " + oneMatricula.periodo}
                            </MKTypography>
                            </>
                        ) : (
                            <MKTypography display='inline' sx={{fontSize: '15px', fontStyle: 'italic', color: '#CCC'}}>
                                Seleccione matrícula
                            </MKTypography>
                        )}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableBody>
                        <TableRow hover sx={{borderBottom: '2px solid #CCC', fontStyle: 'italic'}}>
                            <TableCell align="left">Matrícula</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="right">
                                <Tooltip title='Aperturar matrícula'>
                                    <IconButton color='text' onClick={handleOpenNewMatricula} ><InsertDriveFileIcon fontSize='small' /></IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        {dataMatriculas && dataMatriculas
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(matricula => {
                            descMatricula = matricula.anio.toString() + " - " + matricula.periodo;
                            return(
                                <TableRow key={"matricula" + matricula.id_matricula} hover sx={{cursor: 'pointer'}} onClick={() => handleSelectMatricula(matricula)}>
                                    <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>{descMatricula}</TableCell>
                                    <TableCell align="left" sx={{verticalAlign: 'top', fontSize: '14px'}}>
                                        {matricula.estadoMatricula === 1 ? "Aperturado" : <p style={{fontStyle: 'italic', color: '#8D8D8D'}}>Cerrado</p>}
                                    </TableCell>
                                    <TableCell align='right'>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[5,10,50,100]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={dataMatriculas ? dataMatriculas.length : 0 }
                    labelRowsPerPage=''
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </AccordionDetails>
        </Accordion>


        <Dialog onClose={handleCloseDialog} open={openDialogMatricula} maxWidth="xs" fullWidth>
            <DialogContent>
                <Grid container spacing={2} px={3} py={3}>
                    <Grid item xs={12} pb={3}>
                        <Alert severity="warning"><b>Aviso importante!</b> al aperturar una nueva matricula se cerrá la vigente</Alert>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <YearSelect dataYears={arrYears} onSelectYear={handleSelectNewYear} yearSelected={0} title="Año Académico" />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <PeriodoSelect dataPeriods={DATA_PERIOD} onSelectPeriod={handleChangePeriod} periodoSelected={0} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSaveMatricula} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>

        </>
    );
}

export default Matriculas;