import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, TextField, Divider } from "@mui/material";
import { openNotifAction } from "redux/actions/severalAction";
import { saveNewSchool } from "redux/actions/notesAction";
import { getAllScholls } from "redux/actions/notesAction";
import MKTypography from "components/MKTypography";

const RegisterSchool = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerSchool = "schoolReducer";
    const one_school = useSelector(state => state[reducerSchool].one_school);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        id_escuela: 0,
        escuela: "",
    });

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleSaveSchool = async () => {
        if(values.escuela === "") {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        const rptaSave = await saveNewSchool(values);
        if(rptaSave.success) {
            dispatch(openNotifAction(["Registro exitoso!!!", "success", 'filled']));
            dispatch(getAllScholls());
            onChangeAction("list");
        } else {
            dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
        }
    }

    const handleSetAction = () => {
        onChangeAction("list");
    }

    const initData = () => {
        if(one_school !== null) {
            setValues({
                id_escuela: one_school.id_escuela,
                escuela: one_school.escuela,
            });
            setTitle("Actualizando registro");
        }
    }

    useEffect(() => {
        initData();
    }, [one_school]);

    return(
        <Box>
            <MKTypography className='color-secondary' fontWeight='bold'>
                {title}
            </MKTypography>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField 
                        variant="standard"
                        name="escuela"
                        value={values.escuela}
                        placeholder="Nombre de la escuela"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} textAlign='right'>
                    <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSaveSchool}>Guardar</Button>
                    <Button onClick={handleSetAction}>Cancelar</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default RegisterSchool;