import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PeriodoSelect = (props) => {
    const { dataPeriods, onSelectPeriod, periodoSelected } = props;
    const [values, setValues] = useState({
        id_periodo: "1"
    });

    const handleChange = e => {
        let idPeriod = parseInt(e.target.value);
        let onePeriod = dataPeriods.filter(p=> p.id_periodo === idPeriod);
        setValues({ id_periodo: idPeriod });
        if(onePeriod.length > 0) {
            onSelectPeriod(onePeriod[0]);
        }
    }

    useEffect(() => {
        setValues({ id_periodo: periodoSelected });
    }, [periodoSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Periodo</InputLabel>
            <Select
                name="id_periodo"
                value={values.id_periodo}
                label="Periodo"
                required
                onChange={handleChange}
            >
                {/* <MenuItem value="-1" key="-1"></MenuItem> */}
                {dataPeriods && dataPeriods.map(period => {
                    let keyPeriod = "key" + period.id_periodo;
                    let descPeriod = period.periodo + " - " + period.descripcion;
                    return(
                        <MenuItem value={period.id_periodo} key={keyPeriod} >{descPeriod}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default PeriodoSelect;