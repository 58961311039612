import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const MatriculaSelect = (props) => {
    const { dataMatriculas, onSelectMatricula, matriculaSelected } = props;
    const [values, setValues] = useState({
        id_matricula: "1"
    });

    const handleChange = e => {
        let idMatric = parseInt(e.target.value);
        let oneMatr = dataMatriculas.filter(p=> p.id_matricula === idMatric);
        setValues({ id_matricula: idMatric });
        if(oneMatr.length > 0) {
            onSelectMatricula(oneMatr[0]);
        }
    }

    useEffect(() => {
        setValues({ id_matricula: matriculaSelected });
    }, [matriculaSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Matrícula</InputLabel>
            <Select
                name="id_matricula"
                value={values.id_matricula}
                label="Matrícula"
                required
                onChange={handleChange}
            >
                {/* <MenuItem value="-1" key="-1"></MenuItem> */}
                {dataMatriculas && dataMatriculas.map(matricula => {
                    let keyMatric = "key" + matricula.id_matricula;
                    let descMatric = matricula.anio + " - " + matricula.periodo;
                    return(
                        <MenuItem value={matricula.id_matricula} key={keyMatric} >{descMatric}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default MatriculaSelect;